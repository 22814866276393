<template>
    <div class="form-container">
        <h3>欢迎报名{{ meetingInfo.name }}会议</h3>
        <el-card style="width: 100%;height: 100%;overflow-y: auto;">
            <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;">会议信息</h3>
            <div class="flex-row">
                <p class="form-label">会议名称：</p>
                <p class="form-content">{{ meetingInfo.name }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议主题：</p>
                <p class="form-content">{{ meetingInfo.topic }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议地址：</p>
                <p class="form-content">{{ meetingInfo.address }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议规模：</p>
                <p class="form-content">{{ meetingInfo.attend_peoples }}人</p>
            </div>
            <div class="flex-row">
                <p class="form-label"> {{ meetingInfo.type == '单日会议' ? '当天日期：' : '开始日期：' }} </p>
                <p class="form-content">{{ formatShowTime(meetingInfo.start_date) }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">开始时间：</p>
                <p class="form-content">{{ meetingInfo.start_time }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">结束时间：</p>
                <p class="form-content">{{ formatEndTime() }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '多日会议'">
                <p class="form-label">结束日期：</p>
                <p class="form-content">{{ formatShowTime(meetingInfo.end_date) }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">面向观众：</p>
                <p class="form-content">{{ meetingInfo.audiences }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议简介：</p>
                <p class="form-content" v-html="meetingInfo.intro"></p>
            </div>

            <h3 style="text-align: center;margin: 0px 0px;">报名人员信息</h3>
            <el-form :model="registerInfo" :rules="rules" ref="registerForm">
                <el-form-item label="姓名" prop="user_name">
                    <el-input v-model="registerInfo.user_name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="user_mobile">
                    <el-input v-model="registerInfo.user_mobile" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="user_email">
                    <el-input v-model="registerInfo.user_email" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn-register" @click="doRegisterMeeting">注册</div>
        </el-card>
    </div>
</template>
<script>
// 会议注册界面，带会议id和门票id，显示会议信息，底部卡片显示门票Id。注册要填写自己的表单。
import {
    getMeetingInfo,
    addMeetingApplication,
    getOpenIdForFWH
} from '../api/test'
import {
    formatDate
} from '../utils/date'
import {
    setUserId
} from '../utils/store'
import Cookies from 'js-cookie'
export default ({
    name: 'index',
    data() {
        return {
            open_id: '',
            meeting_id: this.$route.query.meeting_id,
            ticket_id: this.$route.query.ticket_id,
            special_user_email: this.$route.query.user_email,
            special_user_mobile: this.$route.query.user_mobile,
            wx_code: this.$route.query.code,
            meetingInfo: {},
            registerInfo: {
                meeting_id: this.meeting_id,
                ticket_id: this.ticket_id,
                user_mobile: '',
                user_name: '',
                user_email: '',
                open_id: this.open_id
            },
            rules: {
                user_name: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                user_mobile: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                user_email: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {

        if (false) {
            this.meeting_id = '65bb0c16a4640bcf788b4634'
            this.ticket_id = '65d04d79a4640b94298b4576'
            this.open_id = 'oOLA56oqOwd4e1WAzo8OpcK_Zk-k'
            this.fetchData()
            return
        }

        let showType = "request_open_id";
        if (
            this.$route.query.show_type &&
            this.$route.query.show_type == "show_view"
        ) {
            //这个时候从cookies里面获取
            showType = this.$route.query.show_type;
            this.wx_code = this.$route.query.code;
            this.meeting_id = Cookies.get('meeting_id')
            this.ticket_id = Cookies.get('ticket_id')
            this.special_user_email = Cookies.get('special_user_email')
            this.special_user_mobile = Cookies.get('special_user_mobile')
            Cookies.set('meeting_id', "")
            Cookies.set('ticket_id', "")
            Cookies.set('special_user_email', '')
            Cookies.set('special_user_mobile', '')
        } else {
            //这个时候从路由里面获取，测试环境先写死
            // this.meeting_id = '65bb0c16a4640bcf788b4634'
            // this.ticket_id = '65d04d79a4640b94298b4576'
            // this.special_user_email = 'yinzehu@sina.cn'
            Cookies.set('meeting_id', this.meeting_id)
            Cookies.set('ticket_id', this.ticket_id)
            if (this.special_user_email) {
                Cookies.set('special_user_email', this.special_user_email)
            }
            if (this.special_user_mobile) {
                Cookies.set('special_user_mobile', this.special_user_mobile)
            }
        }
        if (showType == 'request_open_id') {
            this.gotoOauth()
        } else {
            if (this.special_user_email) {
                this.registerInfo.user_email = this.special_user_email
            }
            if (this.special_user_mobile) {
                this.registerInfo.user_mobile = this.special_user_mobile
            }
            this.fetchData()
            getOpenIdForFWH(this.wx_code).then((res) => {
                this.open_id = res.data
            })
        }
    },
    methods: {
        gotoOauth() {
            const appId = "wxa6e97f80ff729b4b";
            const redirectUrl = "http://canhaohui-fwh.reachable-edu.com/meetingForm?show_type=show_view";
            window.location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                appId +
                "&redirect_uri=" +
                redirectUrl +
                "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1";
        },
        fetchData() {
            getMeetingInfo(this.meeting_id).then((res) => {
                this.meetingInfo = res.data.data
            })
        },
        doRegisterMeeting() {
            this.$refs.registerForm.validate((val) => {
                if (val) {
                    this.registerInfo.meeting_id = this.meeting_id
                    this.registerInfo.ticket_id = this.ticket_id
                    this.registerInfo.open_id = this.open_id
                    addMeetingApplication(this.registerInfo).then((res) => {
                        let result = res.data
                        let user_id = result.data.user_id
                        setUserId(user_id)
                        let status = result.data.pay_status
                        if (result.code == 0) {
                            if (status == '已支付') {
                                this.$router.replace('/home')
                                this.$message.success('报名成功')
                            } else {
                                this.$router.push({
                                    path: '/pay',
                                    query: {
                                        application_id: result.data._id.$id
                                    }
                                })
                                this.$message.success('报名成功,请立即支付')
                            }
                        } else {
                            if (status == '已支付') {
                                this.$router.replace('/home')
                                this.$message.success(result.msg)
                            } else {
                                this.$router.push({
                                    path: '/pay',
                                    query: {
                                        application_id: result.data._id.$id
                                    }
                                })
                                this.$message.success('报名成功,请立即支付')
                            }
                        }

                    })
                }
            })
        },

        formatEndTime() {
            let start_time = this.meetingInfo.start_time
            let startTimes = start_time.split(':')
            let hours = this.formatTime(startTimes[0]) * 60 * 60
            let mins = this.formatTime(startTimes[1]) * 60
            let seconds = this.formatTime(startTimes[2])
            let startSeconds = hours + mins + seconds
            var totalSeconds = startSeconds + this.meetingInfo.duration

            var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
            totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
            var minute = Math.floor(totalSeconds / 60); // 计算分钟
            totalSeconds %= 60; // 取余得到不足1分钟的秒数

            return this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)

        },
        formatTime(time) {
            return parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
        },
        formatTimeToString(time) {
            if (time >= 10) {
                return time
            }
            return '0' + time
        },

        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        }
    }
})
</script>
<style scoped lang="scss">
.form-container {
    width: 100vw;
    height: 100vh;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.flex-row {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
}

.form-label {
    margin: 0px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.form-content {
    flex: 1;
    margin: 0px;
    font-size: 16px;
    color: #666;
}

.btn-register {
    cursor: pointer;
    margin: 10px 0px;
    color: white;
    font-size: 18px;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}
</style>