import Vue from 'vue';
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import store from './store'
import Vconsole from 'vconsole'

import {
    Tabbar,
    TabbarItem,
    Empty,
    NavBar,
    Tabs,
    Tab,
    Icon,
    Popup,
} from 'vant';

Vue.use(Empty);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Icon);
Vue.use(Popup);

Vue.config.productionTip = false
// if (process.env.NODE_ENV == 'development') {
let vConsole = new Vconsole()
Vue.use(vConsole)
// }
Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
