/**
 * Created by huzeyin on 2020/5/12.
 */
import request from '../utils/request'
import Router from '@/router'

export function getSessionPPT(param) {
    return request.post('./index.php', {
        function: 'getSessionPPT',
        ...param
    })
}

export function getMeetingInfo(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingInfo',
        meeting_id
    })
}

export function addMeetingApplication(params) {
    return request.post('./index.php', {
        function: 'addMeetingApplication',
        meeting_id: params.meeting_id,
        ticket_id: params.ticket_id,
        user_mobile: params.user_mobile,
        user_name: params.user_name,
        user_email: params.user_email,
        open_id: params.open_id
    })
}

export function getUserApplications(user_id) {
    return request.post('./index.php', {
        function: 'getUserApplications',
        user_id
    })
}
export function getUserMeetingApplications(user_id, meeting_id) {
    return request.post('./index.php', {
        function: 'getUserMeetingApplications',
        user_id,
        meeting_id
    })
}


export function getSessionInfo(param) {
    return request.post('./index.php', {
        function: 'getSessionInfo',
        ...param
    })
}
export function getPPTNotes(params) {
    return request.post('/Canhaohui/index.php', {
        function: 'getPPTNotes',
        ...params
    })
}

export function getOpenIdForFWH(code) {
    return request.post('./index.php', {
        function: 'getOpenIdForFWH',
        code
    })
}

export function getApplicationDetail(id) {
    return request.post('./index.php', {
        function: 'getApplicationDetail',
        id
    })
}
export function getUserInfoById(user_id) {
    return request.post('./index.php', {
        function: 'getUserInfoById',
        user_id
    })
}

export function getUserMeetingSettings(user_id) {
    return request.post('./index.php', {
        function: 'getUserMeetingSettings',
        user_id
    })
}

export function updateUserMeetingSettings(params) {
    return request.post('./index.php', {
        function: 'updateUserMeetingSettings',
        id: params.id,
        meeting_id: params.meeting_id,
        user_id: params.user_id,
        remind: params.remind
    })
}

export function loginFWH(user_name, password, open_id) {
    return request.post('./index.php', {
        function: 'loginFWH',
        user_name,
        password,
        open_id
    })
}


// 新增获取验证码接口
export function getPhoneCode(param) {
    return request.post('./index.php', {
        function: 'getPhoneCode',
        ...param
    })
}
// 新增手机好登录接口
export function loginMOB(mobile, code, open_id) {
    return request.post('./index.php', {
        function: 'LoginMOB',
        mobile,
        code,
        open_id
    })
}


export function getOpenMeetings() {
    return request.post('./index.php', {
        function: 'getOpenMeetings',
    })
}


export function getMeetingHandbooks(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingHandbooks',
        meeting_id
    })
}

export function getTickets(meeting_id) {
    return request.post('./index.php', {
        function: 'getTickets',
        meeting_id
    })
}

export function createMeetingApplication(meeting_id, ticket_id, user_id) {
    return request.post('./index.php', {
        function: 'createMeetingApplication',
        meeting_id,
        ticket_id,
        user_id
    })
}

export function startWXPay(order_id) {
    return request.post('./index.php', {
        function: 'startWXPay',
        order_id
    })
}

export function getSignPackage() {
    return request.post('./index.php', {
        function: 'getSignPackage'
    })
}

export function checkUserMeetingApplication(user_id, meeting_id) {
    return request.post('./index.php', {
        function: 'checkUserMeetingApplication',
        user_id,
        meeting_id
    })
}

export function createApplicationOrder(application_id) {
    return request.post('./index.php', {
        function: 'createApplicationOrder',
        application_id
    })
}

export function getSessions(meeting_id) {
    return request.post('./index.php', {
        function: 'getSessions',
        meeting_id
    })
}

export function getSessionDetail(id) {
    return request.post('./index.php', {
        function: 'getSessionDetail',
        id
    })
}

export function verifyTicket(param) {
    return request.post('./index.php', {
        function: 'verifyTicket',
        ...param
    })
}

export function updateWatchPPTNotes(params) {
    return request.post('./index.php', {
        function: 'updateWatchPPTNotes',
        ...params
    })
}
export function updateWatchPPTNoteNew(params) {
    return request.post('./index.php', {
        function: 'updateWatchPPTNoteNew',
        ...params
    })
}

export function getWatchSessionPPT(params) {
    return request.post('./index.php', {
        function: 'getWatchSessionPPT',
        ...params
    })
}

export function snapshotPPT(params) {
    return request.post('./index.php', {
        function: 'snapshotPPT',
        ...params
    })
}

export function getUserSessionNotes(meeting_id, user_id) {
    return request.post('./index.php', {
        function: 'getUserSessionNotes',
        meeting_id,
        user_id
    })
}

export function sendServiceMessage(messageData) {
    return request.post('./index.php', {
        function: 'sendServiceMessage',
        message: messageData
    })
}

export function getServiceMessagesByDialogue(talkers_array) {
    return request.post('./index.php', {
        function: 'getServiceMessagesByDialogue',
        talkers: talkers_array
    })
}

/** 获取品牌客户的列表（赞助商、合作方等都算） */
export function getMeetingPartners(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingPartners',
        meeting_id
    })
}

/** 获得客服的对话框（分举办者和品牌客户） */
export function getUserMessagePartners(user_id) {
    return request.post('./index.php', {
        function: 'getUserMessagePartners',
        user_id
    })
}
// 获取用户快照
export function getUserSnapshotPPTs(param) {
    return request.post('./index.php', {
        function: 'getUserSnapshotPPTs',
        ...param
    })
}
export function removeSnapShot(param) {
    return request.post('./index.php', {
        function: 'removeSnapShot',
        ...param
    })
}

/** 获取七牛token */
export function getQiniuToken(param) {
    return request.post('./index.php', {
        function: 'getQiniuToken',
        ...param
    })
}

export function loginByOpenId(param) {
    return request.post('./index.php', {
        function: 'loginByOpenId',
        ...param
    })
}

export function getPartnerInfo(partner_id) {
    return request.post('./index.php', {
        function: 'getPartnerInfo',
        partner_id
    })
}

export function loginByWXCode(code) {
    return request.post('./index.php', {
        function: 'loginByWXCode',
        code
    })
}

export function updateUserName(param) {
    return request.post('./index.php', {
        function: 'updateUserName',
        ...param
    })
}

// 用户后台签到
export function userCheckIn(param) {
    const query = {}
    location.search.slice(1).split('&').forEach(item => {
        const [attr, val] = item.split('=')
        query[attr] = val
    })
    param.page_router = {
        path: location.origin + location.pathname,
        query
    }
    console.log('userCheckIn', param)
    return request.post('./index.php', {
        function: 'userCheckIn',
        ...param
    }).then(res => {
        if (res.data.code < 0) {
            Router.replace({
                name: 'checkInFailed'
            })
        }
        return res
    })
}

export function userAgree(param) {
    return request.post('./index.php', {
        function: 'userAgree',
        ...param
    })
}

export function getAgreements(param) {
    return request.post('./index.php', {
        function: 'getAgreements',
        ...param
    })
}

export function getShowFile (param) {
    return request.post('./index.php', {
        function: 'getShowFile',
        ...param
    })
}
