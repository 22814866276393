<template>
    <div class="form-container">
        <el-card style="width: 100%;height: 100%;overflow-y: auto;">
            <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;">会议信息</h3>
            <div class="flex-row">
                <p class="form-label">会议名称：</p>
                <p class="form-content">{{ meetingInfo.name }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议主题：</p>
                <p class="form-content">{{ meetingInfo.topic }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议地址：</p>
                <p class="form-content">{{ meetingInfo.address }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议规模：</p>
                <p class="form-content">{{ meetingInfo.attend_peoples }}人</p>
            </div>
            <div class="flex-row">
                <p class="form-label"> {{ meetingInfo.type == '单日会议' ? '当天日期：' : '开始日期：' }} </p>
                <p class="form-content">{{ formatShowTime(meetingInfo.start_date) }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">开始时间：</p>
                <p class="form-content">{{ meetingInfo.start_time }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">结束时间：</p>
                <p class="form-content">{{ formatEndTime() }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '多日会议'">
                <p class="form-label">结束日期：</p>
                <p class="form-content">{{ formatShowTime(meetingInfo.end_date) }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">面向观众：</p>
                <p class="form-content">{{ meetingInfo.audiences }}</p>
            </div>
            <div class="flex-row" style="flex-direction: column;">
                <p class="form-label">会议简介：</p>
                <p class="form-content" style="margin-top: 10px;" v-html="meetingInfo.intro"></p>
            </div>
            <div class="flex-row" style="margin-top: 25px;">
                <p class="form-label">门票编号：</p>
                <p class="form-content" style="font-weight: bold;">{{ ticket_num }}
                </p>
            </div>
            <div class="common-btn" @click="gotoCheckHandbooks()">查看会议手册</div>
            <div class="common-btn" style="margin-top: 20px;" @click="gotoSessions()">查看会议日程</div>
            <div class="common-btn" style="margin-top: 20px;" @click="gotoSessionsNote()">查看会议日程笔记</div>

        </el-card>
    </div>
</template>
<script>
import {
    getApplicationDetail
} from '../api/test'
import '../assets/common/common.css'
import {
    formatDate
} from '../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            application_id: this.$route.query.application_id,
            meetingInfo: {},
            ticket_num: ''
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        checkContacts(){

        },

        gotoSessions() {
            this.$router.push({
                path: '/sessions',
                query: {
                    meeting_id: this.meetingInfo._id.$id
                }
            })
        },

        gotoSessionsNote(){
            this.$router.push({
                path: '/sessionNotes',
                query: {
                    meeting_id: this.meetingInfo._id.$id
                }
            })
        },

        gotoCheckHandbooks() {
            this.$router.push({
                path: '/handbooks',
                query: {
                    meeting_id: this.meetingInfo._id.$id
                }
            })
        },

        fetchData() {
            getApplicationDetail(this.application_id).then((res) => {
                let result = res.data.data
                this.meetingInfo = result.meeting
                this.ticket_num = result.ticket_num
            })
        },
        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        }
    }
})
</script>

<style scoped lang="scss">

.form-container {
    width: 100vw;
    height: 100vh;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.flex-row {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
}

.form-label {
    margin: 0px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.form-content {
    flex: 1;
    margin: 0px;
    font-size: 16px;
    color: #666;
    line-height: 24px;
}
</style>