import Vue from 'vue'
import Router from 'vue-router'

import { getUserId } from '../utils/store'
import watch from '../pages/watch.vue'
import meetingForm from '../pages/meetingForm.vue'
import Home from '../pages/home.vue'
import Applications from '../pages/applications.vue'
import Square from '../pages/square.vue'
import UserInfo from '../pages/userinfo.vue'
import control from '../pages/control.vue'
import controlPdf from '../pages/control-pdf.vue'
import watchPdf from '../pages/watch-pdf/watch-pdf.vue'
import ApplicationDetail from '../pages/applicationDetail.vue'
import MeetingDetail from '../pages/meetingDetail.vue'
import MeetingSetting from '../pages/meetingSetting.vue'
import Login from '../pages/login/login.vue'
import Handbooks from '../pages/handbooks.vue'
import SelectTicket from '../pages/selectTicket.vue'
import Pay from '../pages/pay.vue'
import Entry from '../pages/entry.vue'
import ViewMeeting from '../pages/viewMeeting.vue'
import MeetingSessions from '../pages/sessions.vue'
import SessionGuests from '../pages/guests.vue'

import MeetingSessionNotes from '../pages/sessionNotes.vue'


Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
    mode: 'history',
    routes: [{
            path: '/watch',
            name: 'watch',
            component: watch,
            meta: {
                requiresAuth: false,
                title: '观看PPT'
            }
        },
        {
            path: '/entry',
            name: 'index',
            component: Entry,
            meta: {
                requiresAuth: false,
                title: '登录'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false,
                title: '登录'
            }
        },
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/control',
            name: 'control',
            component: control,
            meta: {
                requiresAuth: false,
                title: '控制PPPT'
            }
        },
        {
            path: '/watch-pdf',
            name: 'watchPdf',
            component: watchPdf,
            meta: {
                requiresAuth: true,
                title: '观看PDF'
            }
        },
        {
            path: '/control-pdf',
            name: 'controlPdf',
            component: controlPdf,
            meta: {
                requiresAuth: false,
                title: '观看PDF'
            }
        },
        {
            path: '/meetingForm',
            name: 'meetingForm',
            component: meetingForm,
            meta: {
                requiresAuth: false,
                title: '注册会议'
            }
        },
        {
            path: '/home',
            name: 'index',
            component: Home,
            redirect: '/square',
            meta: {
                requiresAuth: true,
                title: '参好会'
            },
            children: [{
                    path: '/square',
                    component: Square,
                    meta: {
                        requiresAuth: true,
                        title: '参好会'
                    },
                }, , {
                    path: '/applications',
                    component: Applications,
                    meta: {
                        requiresAuth: true,
                        title: '参好会'
                    },
                },
                {
                    path: '/userinfo',
                    component: UserInfo,
                    meta: {
                        requiresAuth: true,
                        title: '参好会'
                    },
                },
            ]
        },
        {
            path: '/application',
            name: 'index',
            component: ApplicationDetail,
            meta: {
                requiresAuth: true,
                title: '会议详情'
            }
        },
        {
            path: '/meetingSetting',
            name: 'index',
            component: MeetingSetting,
            meta: {
                requiresAuth: true,
                title: '会议设置'
            }
        },
        {
            path: '/meeting',
            name: 'index',
            component: MeetingDetail,
            meta: {
                requiresAuth: false,
                title: '会议详情'
            }
        },
        {
            path: '/handbooks',
            name: 'index',
            component: Handbooks,
            meta: {
                requiresAuth: false,
                title: '会议手册'
            }
        },
        {
            path: '/selectTicket',
            name: 'index',
            component: SelectTicket,
            meta: {
                requiresAuth: false,
                title: '选择门票'
            }
        },
        {
            path: '/pay',
            name: 'index',
            component: Pay,
            meta: {
                requiresAuth: true,
                title: '门票支付'
            }
        },
        {
            path: '/viewMeeting',
            name: 'index',
            component: ViewMeeting,
            meta: {
                requiresAuth: false,
                title: '查看会议'
            }
        },
        {
            path: '/sessions',
            name: 'index',
            component: MeetingSessions,
            meta: {
                requiresAuth: false,
                title: '会议日程'
            }
        },
        {
            path: '/sessionNotes',
            name: 'index',
            component: MeetingSessionNotes,
            meta: {
                requiresAuth: false,
                title: '会议日程笔记'
            }
        },
        {
            path: '/sessionGuests',
            name: 'index',
            component: SessionGuests,
            meta: {
                requiresAuth: false,
                title: '日程嘉宾'
            }
        },
        {
            path: '/checkInFailed',
            name: 'checkInFailed',
            component: () => import('@/pages/checkInFailed.vue'),
            meta: {
                requiresAuth: false,
                title: '参考会'
            }
        }
    ]
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        const userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    }

})

router.afterEach((to, from, next) => {
    window,
    scrollTo(0, 0)
})
export default router