<template>
  <div style="
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      position: relative;
    ">
    <div style="flex: 1; width: 100%; height: 100%; overflow-y: auto">
      <router-view />
    </div>
    <div class="tab-container">
      <div v-for="(tab, index) in tabs" :key="index" class="tab-item" @click="handleTabChanged(index)">
        <img :src="tab.selected ? tab.selectedTabIcon : tab.normalTabIcon" class="tab-icon" />
        <p :class="tab.selected ? 'tab-title-selected' : 'tab-title-normal'">{{ tab.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeTabIndex, setHomeTabIndex } from "../utils/store";

export default {
  name: "index",
  data() {
    return {
      currentTab: -1,
      tabs: [
        {
          selected: true,
          title: "广场",
          normalTabIcon: require("../assets/square_normal.png"),
          selectedTabIcon: require("../assets/square_selected.png"),
          routerPath: "/square",
        },
        {
          selected: false,
          title: "会议",
          normalTabIcon: require("../assets/meeting_normal.png"),
          selectedTabIcon: require("../assets/meeting_selected.png"),
          routerPath: "/applications",
        },
        {
          selected: false, 
          title: "我的",
          normalTabIcon: require("../assets/my_normal.png"),
          selectedTabIcon: require("../assets/my_selected.png"),
          routerPath: "/userinfo",
        },
      ],
    };
  },
  mounted() {
    const tabIndex = getHomeTabIndex();
    console.log('tabIndex :'+ tabIndex)
    if (tabIndex) {
      this.handleTabChanged(tabIndex);
    } else {
      this.handleTabChanged(0);
    }
  },
  methods: {
    handleTabChanged(index) {
      if (this.currentTab == index) {
        return;
      }
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].selected = false;
      }
      this.tabs[index].selected = true;
      this.currentTab = index;
      let path = this.tabs[index].routerPath;
      this.$router.push(path);
      setHomeTabIndex(index);
    },
  },
};
</script>

<style scoped lang="scss">
.isPlayStatus {
  position: absolute;
  bottom: 53px;
  height: 86px;
  //background-color: red;
  z-index: 999;
  background: white;
  width: 100vw;
}

.tab-container {
  margin: 0xp;
  padding: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
}

.tab-item {
  height: 65px;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab-icon {
  object-fit: cover;
  width: 24px;
  height: 24px;
}

.tab-title-normal {
  font-size: 12px;
  font-weight: normal;
  color: #999999;
  margin-top: 8px;
  margin-bottom: 0px;
}

.tab-title-selected {
  font-size: 12px;
  font-weight: normal;
  color: #409EFF;
  margin-top: 8px;
  margin-bottom: 0px;
}
</style>