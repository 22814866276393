<template>
  <div class="pdfContainer" v-show="pageReady">
    <div class="verify" v-if="!verifyStatus">
      <div style="width: 100%; text-align: center">
        <el-input
          v-model="tocket_num"
          style="width: 80%"
          placeholder="请输入门票编号"
        ></el-input>
        <div style="margin-top: 20px">
          <el-button type="primary" @click="verify">验证</el-button>
        </div>
      </div>
    </div>
    <div class="onlineStatus">
      <div>当前状态：</div>
      <div class="statusIcon" :class="status ? 'green' : 'red'"></div>
      <div>{{ status ? "在线" : "离线" }}</div>
      <!-- <div style="margin-left: 26px">演示者状态：</div>
      <div
        class="statusIcon"
        :class="status && presenterStatus ? 'green' : 'red'"
      ></div>
      <div>{{ status && presenterStatus ? "在线" : "离线" }}</div> -->
    </div>
    <div id="bigshow">
      <div
        style="
          width: 100%;
          height: 230px;
          /* display: flex; */
          background-color: red;
          /* justify-content: center; */
          /* align-items: center; */
        "
      >
        <div style="position: relative" id="show">
          <div id="firstSlide" v-if="currentIndex === 0">
            <div>
              <div
                style="margin-top: 10px; text-align: center; font-size: 20px"
              >
                {{ sessionName }}
              </div>
              <vue-qr :text="watchUrl" :size="200"></vue-qr>
            </div>
          </div>
          <canvas
            @click="actionPreview(curPageImageUrl)"
            id="pdf-render"
            :key="canvasKey"
          ></canvas>
          <!-- <div class="shuiyin">
            <div>{{ user_name }} {{ user_mobile }}</div>
          </div> -->
        </div>
      </div>
      <div class="meeting-info">
        <div class="meeting-info-header">
          <div class="meeting-info-header-title">{{ user_name }}</div>
          <div class="meeting-info-header-extra">
            ({{ this.currentIndex + 1 }}/{{ total + 1 }})
          </div>
        </div>
        <div class="meeting-info-desc">
          {{ sessionInfo.meeting?.name }}
          <span style="margin-left: 4px">{{ sessionInfo.name }}</span>
        </div>
      </div>
    </div>
    <!-- 测试说删除观众页面的倒计时 -->
    <!-- <div v-if="endTime" class="endTime">{{ endTime }}</div> -->
    <div class="btnGroup">
      <div class="cusBtn" style="" @click="last">上一页</div>

      <div
        type="primary"
        @click="goback"
        class="cusBtn"
        style="background-color: #0080ff; color: white"
      >
        当前页
      </div>

      <div type="primary" class="cusBtn" @click="next" style="">下一页</div>

      <!-- <el-button
          type="primary"
          @click="toScreen"
          class="cusBtn"
          style=""
          >全屏</el-button
        > -->

      <div
        type="primary"
        @click="snapshot"
        class="cusBtn"
        style="background-color: #ff943a; color: white"
      >
        快照
      </div>
    </div>

    <div class="contentClass">
      <div class="tabClass">
        <div
          style="
            margin-left: 20%;
            width: 30%;
            float: left;
            text-align: center;
            height: 40px;
            line-height: 40px;
          "
          @click="bijiClick"
          :style="selectType == 'note' ? 'color: #0080FF' : 'color: #909090'"
        >
          笔记
        </div>

        <div
          style="
            width: 30%;
            float: left;
            text-align: center;
            height: 40px;
            line-height: 40px;
          "
          @click="fileClick"
          :style="selectType == 'file' ? 'color: #0080FF' : 'color: #909090'"
        >
          附件
        </div>
      </div>

      <el-input
        @focus="inputFocus = true"
        @blur="inputFocus = false"
        @change="updateWatchPPTNoteNew"
        v-model="templeContent"
        placeholder="笔记"
        type="textarea"
        :rows="5"
        v-if="selectType == 'note'"
        style="margin-top: 20px; width: 90%; margin-left: 5%"
      ></el-input>

      <div v-if="attachments.length > 0 && selectType == 'file'" style="">
        <Attachment
          v-for="(item, index) in attachments"
          :key="index"
          :data="item"
        ></Attachment>
      </div>
    </div>

    <div
      style="width: 100%; height: 40px"
      @click="showMessage()"
      v-if="!inputFocus"
    >
      <!-- <el-button type="primary" @click="snapshot">快照</el-button> -->
      <div
        style="
          width: 70%;
          margin-left: 5%;
          float: left;
          background-color: #e0edfd;
          height: 40px;
          color: #9bcdff;
          line-height: 40px;
          border-radius: 5px;
          font-size: 12px;
          text-align: left;
        "
      >
        <span style="padding-left: 30px">说点什么吧</span>
      </div>
      <div
        style="
          width: 15%;
          margin-left: 5%;
          float: left;
          background-color: #0080ff;
          height: 40px;
          line-height: 40px;
          border-radius: 5px;
          color: white;
          font-size: 12px;
          text-align: center;
        "
      >
        交流咨询
      </div>
      <!-- <el-button type="primary" @click="showDrawer">交流咨询</el-button> -->
    </div>

    <!-- <div class="fullscreen">
      <canvas id="pdf-render" :key="canvasKey"></canvas>

    </div> -->

    <div v-if="showMessageDia" class="message-page">
      <div class="message-top" @click="showMessageDia = false"></div>

      <!-- border-radius: 左上角 右上角 右下角 左下角; -->
      <div class="message-content">
        <div class="message-bg">
          <div class="bg-top" @click="showMessageDia = false">
            <div class="kf-status">
              <div>
                <span>客服状态：</span>
                <span
                  class="statusIcon"
                  :style="status ? 'background: green' : 'background: red'"
                ></span>
                <span>{{ status ? "在线" : "离线" }}</span>
              </div>
            </div>
          </div>

          <div
            ref="messageBoxContent"
            style="
              background-color: white;
              margin-top: 0px;
              height: calc(100% - 40px);
            "
          >
            <div
              v-for="(item, index) in messageList"
              :key="index"
              :class="item.senderID == user_id ? 'rightMessage' : 'leftMessage'"
            >
              <span v-if="item.message_time" class="time">
                {{
                  dayjs(item.message_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                }}
              </span>
              <div class="messageBox">
                <div class="message-name">{{ item.sender_name }}</div>
                <div class="message-customer-msg">{{ item.message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 100%; height: 40px" @click="showMessage()">
          <div
            style="
              width: 70%;
              margin-left: 5%;
              float: left;
              background-color: #e0edfd;
              height: 40px;
              color: #9bcdff;
              line-height: 40px;
              border-radius: 5px;
              font-size: 12px;
              text-align: left;
            "
          >
            <el-input v-model="messageStr" placeholder="说点什么吧"></el-input>
          </div>
          <div
            style="
              width: 15%;
              margin-left: 5%;
              float: left;
              background-color: #0080ff;
              height: 40px;
              line-height: 40px;
              border-radius: 5px;
              color: white;
              font-size: 12px;
              text-align: center;
            "
            @click="sendMessage"
          >
            发送消息
          </div>
        </div>
      </div>
    </div>

    <el-drawer title="交流咨询" :visible.sync="drawer" direction="ltr">
      <div style="width: 100%; height: 90%; overflow-y: auto">
        <div
          v-for="(item, index) in messageList"
          :key="index"
          class="messageDiv"
          :style="
            item.senderID == user_id
              ? 'text-align: right;'
              : 'text-align: left;'
          "
        >
          {{ item.message }}
        </div>
      </div>

      <div style="width: 100%; height: 10%">
        <el-input
          v-model="messageStr"
          style="width: 70%; margin-left: 5%"
        ></el-input>
        <el-button style="margin-left: 5%; width: 15%" @click="sendMessage"
          >发送</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import html2canvas from "html2canvas";
import Cookies from "js-cookie";
import * as qiniu from "qiniu-js";
import VueQr from "vue-qr";
import {
  getPPTNotes,
  getQiniuToken,
  getServiceMessagesByDialogue,
  getSessionInfo,
  getSessionPPT,
  getUserInfoById,
  getWatchSessionPPT,
  sendServiceMessage,
  snapshotPPT,
  updateWatchPPTNoteNew,
  verifyTicket,
  getPartnerInfo,
  userCheckIn,
} from "../../api/test";
import { getUserId, setUserId } from "../../utils/store";
import Attachment from "./components/attachment.vue";
const PDFJS = require("pdfjs-dist");
import dayjs from "dayjs";

PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js";

export default {
  components: {
    VueQr,
    Attachment,
  },
  data() {
    return {
      dayjs,
      endTime: null,
      maxIndex: -1, // 当前已浏览的最大页码
      curPageImageUrl: "",
      imgInstance: null,
      timer: null,
      pageReady: false, // 页面是否准备好了
      inputFocus: false,
      currentIndex: null,
      nowCurrentIndex: null,
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      color: "#000000",
      url: "",
      client: null,
      client_msg: null,
      watchUrl: "",
      loading: false,
      presentUrl: "",
      codeVisible: false,
      pdfDoc: null,
      total: 0,
      width: 0,
      height: 0,
      canvasKey: 0,
      sessionInfo: {},
      presenterStatus: false,
      status: false,
      meeting_id: "",
      verifyStatus: false,
      tocket_num: "",
      user_name: "", // 用户姓名
      user_mobile: "", // 用户手机号
      watchNotes: [],
      attachments: [],
      guest_ids: [],
      drawer: false,
      messageStr: "",
      messageList: [],
      user_id: getUserId(),
      selectType: "note", //file or note
      showMessageDia: false,
      templeContent: "",
    };
  },
  mounted() {
    this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch-pdf?id=${this.id}&session_id=${this.session_id}`;
    document.addEventListener("visibilitychange", () => {
      if (!this.client) {
        this.initSocket();
      }
    });
    window.addEventListener("resize", () => {
      this.resize();
    });
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
    this.getSessionInfo().finally(() => {
      // 静默签到
      userCheckIn({
        meeting_id: this.sessionInfo.meeting_id,
        user_id: getUserId(),
      });
    });
    // this.initLoginState()
    this.pageReady = true;
    this.verifyStatus = true;
    this.initPage();
  },
  methods: {
    async getSessionInfo() {
      const sessionInfo = await getSessionInfo({
        session_id: this.session_id,
      });
      if (sessionInfo.data.code !== 0) {
        this.$message.error(sessionInfo.data.msg);
        return;
      }
      this.sessionInfo = sessionInfo.data.data;
    },
    async initLoginState() {
      const sessionInfo = await getSessionInfo({
        session_id: this.session_id,
      });
      if (sessionInfo.data.code !== 0) {
        this.$message.error(sessionInfo.data.msg);
        return;
      }
      this.sessionInfo = sessionInfo.data.data;
      const { check_ticket, guest_ids, meeting_id } = this.sessionInfo;
      /**
       * 流程如下
       * 1. 拿到会议信息，拿到 check_ticket 字段
       * 2. 结合 check_ticket 判断登录态，已登录则跳过第 2 步
       *    - 未登录 && check_ticket = 'YES' 需要校验门票，展示输入门票的弹窗
       *    - 未登录 && check_ticket = 'NO'  不需要门票，使用微信自动登录
       * 3. 登录完成，后续流程无需修改
       */
      // 流程是
      // 验证用户是否登录
      const user_id = getUserId();
      if (user_id) {
        // 已登录
        this.pageReady = true;
        this.verifyStatus = true;
        return this.initPage();
      }
      console.log(123, check_ticket);

      // 未登录流程
      switch (check_ticket) {
        case "YES":
          this.pageReady = true;
          this.verifyStatus = false;
          break;
        case "NO":
          this.$router.push({
            path: "/login",
            // name: "autoLogin",
            query: {
              backUrl: encodeURIComponent(location.href),
            },
          });
          break;
      }
    },
    initPage() {
      const user_id = getUserId();
      getUserInfoById(user_id).then((res) => {
        this.user_name = res.data.name;
        this.user_mobile = res.data.mobile;
      });
      this.fetchData();
      this.getWatchSessionPPT();
    },
    actionPreview(imgUrl) {
      if (!imgUrl) return;
      this.imgInstance = ImagePreview({
        images: [imgUrl],
        closeable: true,
      });
    },
    verify() {
      verifyTicket({
        ticket_num: this.tocket_num,
        meeting_id: this.meeting_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.verifyStatus = true;
          this.fetchData();
          this.$message.success(res.data.msg);
          setUserId(res.data.data.user_id);
          this.user_name = res.data.data.user.name;
          Cookies.set("meeting_id", this.meeting_id);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    resize() {
      if (!this.verifyStatus) {
        return;
      }
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
    },

    async snapshot() {
      const dom = document.getElementById("bigshow");
      // step1: 获取图片
      const file = await new Promise((resolve) => {
        html2canvas(dom).then((canvas) => {
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, "image/jpeg");
        });
      });
      // step2: 获取七牛token
      const token = await getQiniuToken().then((res) => {
        if (res.data.code === 0) return res.data.data;
      });
      if (!token) return;
      // step3: 上传七牛
      const url = await new Promise((resolve) => {
        const observable = qiniu.upload(
          file,
          new Date().getTime() + ".png",
          token
        );
        const observer = {
          complete: (res) => {
            resolve("https://mainbucket.reachable-edu.com/" + res.key);
          },
        };
        observable.subscribe(observer);
      });
      if (!url) return;
      // step4: 上传加了水印的快照
      snapshotPPT({
        id: this.id,
        user_id: getUserId(),
        page: this.currentIndex,
        pic: url,
      }).then((res) => {
        this.$message.success("添加快照成功");
      });
    },
    addWatermark(canvas) {
      const ctx = canvas.getContext("2d");
      let text = getUserId();
      const fontSize = 12;
      const tileOffsetY = 120
      const tileOffsetX = 70
      const angle = -30

      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = "#ccc";
      ctx.globalAlpha = 0.2; // 设置透明度
      // 计算文本在旋转后的尺寸（这里简化为只考虑宽度，因为高度会随旋转而变化）
      var textWidth = ctx.measureText(text).width;
      var textHeight =
        fontSize * Math.sin(Math.PI / 4) + fontSize * Math.cos(Math.PI / 4); // 简化的估计

      // 计算每行能放多少个水印（考虑旋转后的宽度和间隔）
      var xStep = textWidth * Math.cos(Math.PI / 4) + tileOffsetX; // 旋转后的宽度加间隔
      var columns = Math.floor((canvas.width + (tileOffsetX * 3)) / xStep);

      // 计算需要绘制多少行（交错时，每行的Y坐标会基于tileOffsetY调整）
      var lineHeight = textHeight + tileOffsetY; // 交错偏移量
      var rows = Math.ceil((canvas.height + tileOffsetY) / lineHeight);

      // 遍历每一行和每一列来绘制水印
      for (var i = 0; i < rows; i++) {
        for (var j = 0; j < columns; j++) {
          var x = j * xStep;
          var y = i * lineHeight;
          if (j % 2 === 0) {
            y -= 50;
          } else {
            y += 20;
          }

          // 保存当前状态
          ctx.save();

          // 移动到绘制位置并旋转
          ctx.translate(x + textWidth / 2, y + textHeight / 2);
          ctx.rotate((angle * Math.PI) / 180); // 将角度转换为弧度

          // 绘制文本（现在原点在文本的中心）
          ctx.fillText(text, -textWidth / 2, -textHeight / 2);

          // 恢复状态
          ctx.restore();
        }
      }
    },
    getWatchSessionPPT() {
      getWatchSessionPPT({
        id: this.id,
        user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.watchNotes = res.data.data ? res.data.data : [];
          this.getTempleContent();
        }
      });
    },
    getTempleContent() {
      let item = this.watchNotes.find(
        (item) => item.index === this.currentIndex
      );
      if (item) {
        this.templeContent = item.content;
      } else {
        this.templeContent = "";
      }
    },
    async updateWatchPPTNoteNew() {
      const dom = document.getElementById("bigshow");
      // step1: 获取图片
      const file = await new Promise((resolve) => {
        html2canvas(dom).then((canvas) => {
          canvas.toBlob(function (blob) {
            resolve(blob);
          }, "image/jpeg");
        });
      });
      // step2: 获取七牛token
      const token = await getQiniuToken().then((res) => {
        if (res.data.code === 0) return res.data.data;
      });
      if (!token) return;
      // step3: 上传七牛
      const url = await new Promise((resolve) => {
        const observable = qiniu.upload(
          file,
          new Date().getTime() + ".png",
          token
        );
        const observer = {
          complete: (res) => {
            resolve("https://mainbucket.reachable-edu.com/" + res.key);
          },
        };
        observable.subscribe(observer);
      });
      updateWatchPPTNoteNew({
        id: this.id,
        content: this.templeContent,
        user_id: getUserId(),
        index: this.currentIndex,
        pic: url,
      }).then((res) => {
        this.getWatchSessionPPT();
      });
    },
    getPPTNotes() {
      getPPTNotes({ ppt_id: this.id }).then((res) => {
        console.log(res);
      });
    },
    initSocket() {
      if (!this.verifyStatus) {
        return;
      }
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("演示WebSocket连接成功");
        this.status = true;
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
            userId: getUserId(),
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log("e.data", e.data);
        let obj = JSON.parse(e.data);
        if (obj.msg === "主持人状态") {
          this.presenterStatus = obj.data;
        } else if (obj.msg === "客服消息") {
          console.log(111113322, obj);

          let senderID = obj.senderID;
          let messageStr = obj.data;

          let messageData = [];
          messageData["data"] = messageStr;
          messageData["senderID"] = senderID;

          this.messageList.push(messageData);
        } else if (obj.msg === "ppt结束时间") {
          !this.timer ? this.countdown(obj.data * 1000) : null;
        } else if (obj.msg === "登录成功" || obj.msg === "切换页面") {
          this.currentIndex = obj.data;
          this.nowCurrentIndex = obj.data;
          this.getTempleContent();
          this.maxIndex = Math.max(this.maxIndex, obj.data);
          this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        }
      };
      this.client.onclose = () => {
        console.log("演示WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };

      this.client_msg = new WebSocket("wss://team-chat.learningfirst.cn:9021");
      this.client_msg.onopen = () => {
        console.log("消息WebSocket连接成功");
        this.status = true;
        this.client_msg.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
            //可能要装上 sessionId之类的
            userId: getUserId(),
          })
        );
      };
      let that = this;
      this.client_msg.onmessage = (e) => {
        console.log(e.data);
        let obj = JSON.parse(e.data);
        if (obj.msg === "客服消息") {
          that.handleKefuMsgReceived(obj);
        }
      };
      this.client_msg.onclose = () => {
        console.log("消息WebSocket连接关闭");
        this.$message.error("消息WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };
    },
    async handleKefuMsgReceived(obj) {
      let senderID = obj.senderID;
      let partnerResult = await getPartnerInfo(senderID);
      let messageStr = obj.data;
      let messageData = [];
      messageData["message"] = messageStr;
      messageData["senderID"] = senderID;
      messageData["sender_name"] = partnerResult.data.name;
      this.messageList.push(messageData);
    },
    fetchData() {
      this.loading = true;
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.type === "PPT") {
            this.url = res.data.data.pdf_url;
          } else {
            this.url = res.data.data.ppt_url;
          }
          this.attachments = res.data.data.attachments;
          this.sessionName = res.data.data.session.name;
          this.partner_id = res.data.data.session.partner_id;
          PDFJS.getDocument(this.url).promise.then((doc) => {
            this.pdfDoc = doc;
            this.total = doc.numPages;
            this.initSocket();
          });
          let talkers = [this.partner_id, getUserId()];
          getServiceMessagesByDialogue(talkers).then((res) => {
            this.messageList = res.data.data;
          });
        }
      });
    },

    getPdfPage(number) {
      this.canvasKey++;
      this.pdfDoc.getPage(number).then((page) => {
        const viewport = page.getViewport();
        const canvas = document.getElementById("pdf-render");
        const context = canvas.getContext("2d");
        canvas.width = viewport.viewBox[2];
        canvas.height = viewport.viewBox[3];
        viewport.width = viewport.viewBox[2];
        viewport.height = viewport.viewBox[3];

        let pdfWidth = viewport.width;
        let pdfHeight = viewport.height;
        if (pdfWidth > this.width || pdfHeight > this.height) {
          let scale = Math.min(this.width / pdfWidth, this.height / pdfHeight);
          pdfWidth *= scale;
          pdfHeight *= scale;
        }

        canvas.style.width = pdfWidth + "px";
        canvas.style.height = pdfHeight + "px";

        let renderContext = {
          canvasContext: context,
          viewport: viewport,
          // 这里transform的六个参数，使用的是transform中的Matrix(矩阵)
          transform: [1, 0, 0, -1, 0, viewport.height],
        };

        // 进行渲染
        const renderTask = page.render(renderContext);
        renderTask.promise.finally(() => {
          this.addWatermark(canvas);
          canvas.toBlob((blob) => {
            this.curPageImageUrl = URL.createObjectURL(blob);
            if (this.imgInstance?.opened) {
              this.imgInstance.images = [this.curPageImageUrl];
            }
          });
        });
      });
    },

    last() {
      if (this.currentIndex <= 0) {
        this.$message.warning("当前已是第一页");
      } else {
        this.currentIndex--;
        this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        this.getTempleContent();
      }
    },
    next() {
      if (this.currentIndex >= this.maxIndex) {
        this.$message.warning("当前已是最新页");
      } else {
        this.currentIndex++;
        this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        this.getTempleContent();
      }
    },
    goback() {
      this.currentIndex = this.nowCurrentIndex;
      this.getTempleContent();
      this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
    },
    showDrawer() {
      this.messageStr = "";
      this.drawer = true;
    },
    sendMessage() {
      // let pptId = dataObj.pptId;
      //       let message = dataObj.message;
      //       let senderID = dataObj.senderID;
      //       let receiveID = dataObj.receiveID;

      // for (let i = 0; i < this.guest_ids.length; i++) {
      //TODO 还得更改上传的参数

      if (this.partner_id != "") {
        // console.log(1111,this.id,this.messageStr,getUserId(),guestId)
        var messageToSend = JSON.stringify({
          type: "serviceMessage",
          msg: "客服消息",
          pptId: this.id,
          message: this.messageStr,
          senderID: getUserId(),
          receiveID: this.partner_id,
          sender_name: this.user_name,
          sender_mobile: this.user_mobile,
          message_time: parseInt(Date.now() / 1000),
        });
        this.client_msg.send(messageToSend);

        sendServiceMessage(messageToSend);
        console.log(messageToSend);

        let messageData = [];
        // messageData['data'] = this.messageStr
        // messageData['senderID'] = getUserId()

        this.messageList.push(JSON.parse(messageToSend));
      } else {
        this.$message.warning("抱歉，本场议程没有品牌方官方客服");
      }
      // }

      this.messageStr = "";

      console.log(1111, this.messageList);
    },
    toScreen() {
      let myCanvas = document.getElementById("show");
      myCanvas.requestFullscreen();
    },

    bijiClick() {
      this.selectType = "note";
    },
    fileClick() {
      this.selectType = "file";
    },
    showMessage() {
      this.showMessageDia = true;
    },
    /** 倒计时 */
    countdown(endTime) {
      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = endTime - now;

        if (distance < 0) {
          clearInterval(this.timer);
          this.endTime = null;
          return;
        }
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.endTime = `${this.formatTime1(hours)} : ${this.formatTime1(
          minutes
        )} : ${this.formatTime1(seconds)}`;
      }, 1000);
    },
    formatTime1(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },
  },
  unmounted() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.meeting-info {
  background: #f7fbff;
  border: 1px solid #e9edf0;
  padding: 8px 16px;
  font-size: 12px;
  color: #0181f9;

  &-header {
    display: flex;
    justify-content: space-between;

    &-title {
      width: 100%;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-extra {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }

  &-desc {
    margin-top: 6px;
    font-size: #0482f9;
  }
}

.endTime {
  text-align: center;
  margin: 2px 0;
}

.pdfContainer {
  width: 100%;
  height: 100%;
  position: relative;

  .verify {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  .pdf {
    width: 1280px;
    height: 720px;
  }

  #firstSlide {
    width: 100%;
    height: calc(100% - 3px);
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #666;
    position: absolute;
    left: 0;
    top: 0px;
    background: #fff;
  }

  .onlineStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    background: #f7fbff;
    font-size: 14px;

    .statusIcon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
    }

    .red {
      background: red;
    }

    .green {
      background: green;
    }
  }

  .btnGroup {
    background-color: #eff7ff;
    width: 100%;
    text-align: center;
    // padding-top: 20px;
    box-sizing: border-box;
    height: 50px;
    // padding-right: 10px;
  }

  .message-page {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);

    .message-top {
      width: 100%;
      height: 310px;
    }

    .message-content {
      height: calc(100% - 310px);
      background-color: white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      .message-bg {
        height: calc(100% - 60px);
        background-color: white;
        overflow-y: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .bg-top {
          height: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .kf-status {
            position: absolute;
            display: flex;
            padding: 2px;
            justify-content: center;
            align-items: center;
            height: 20px;
            line-height: 20px;
            background-color: #f3efef;
            border-radius: 3px;
            font-size: 12px;

            .statusIcon {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 6px;
            }
          }
        }

        .leftMessage {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
          margin-left: 10px;

          .message-name {
            background-color: #e7eaee;
          }

          .message-customer-msg {
            margin-left: 5px;
            font-size: 13px;
            color: #333;
            background-color: #e7eaee;
            padding: 10px;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        .rightMessage {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 10px;
          margin-right: 10px;

          .message-name {
            background-color: #bfd6ec;
          }

          .message-customer-msg {
            font-size: 13px;
            color: #333;
            background-color: #add0f1;
            padding: 10px;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          .messageBox {
            flex-flow: row-reverse;
          }
        }
      }
    }
    .messageBox {
      display: flex;
      gap: 10px;
      max-width: 60%;

      .message-name {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 5px;
      }
    }
  }
}

.time {
  color: #999;
  font-size: 12px;
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.cusBtn {
  float: left;
  margin-left: 4%;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #e0edfd;
  width: 20%;
  height: 30px;
  font-size: 16px;
  text-align: center;
  padding: 0;
  border-width: 0px;
  border-radius: 5px;
  line-height: 30px;
  color: #0080ff;
}

.contentClass {
  width: 100%;
  height: calc(100vh - 440px);
  background-color: red($color: #000000);
  overflow-y: auto;
}

.tabClass {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e2e5ea;
}
</style>
