<template>
    <div class="view-root">
        <div v-for="(handbook, index) in dataList" :key="index" class="handbook-item">
            <el-card>
                <p class="handbook-name">手册名称：{{ handbook.title }}</p>
                <p class="handbook-text" v-html="handbook.content"></p>
            </el-card>
        </div>
    </div>
</template>
<script>
import {
    getMeetingHandbooks,
    userCheckIn
} from '../api/test'
import { getUserId } from "../utils/store";
import '../assets/common/common.css'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: []
        }
    },
    mounted() {
        // 静默签到
        userCheckIn({
            meeting_id: this.meeting_id,
            user_id: getUserId(),
        })
        this.fetchData()
    },
    methods: {
        fetchData() {
            getMeetingHandbooks(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        }
    }
})
</script>
<style scoped lang="scss">
.handbook-item {
    margin-bottom: 15px;

    .handbook-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .handbook-text {
        color: #666;
        font-size: 14px;
        line-height: 22px;
    }

}
</style>