<template>
    <div class="view-root">
        <h3 style="text-align: center;">已报名会议列表</h3>
        <div v-for="(app, index) in dataList" :key="index" class="app-item" @click="gotoMeetingDetail(app)">
            <el-card>
                <p class="meeting-name">会议名称：{{ app.meeting.name }}</p>
                <p class="meeting-text">会议主题：{{ app.meeting.topic }}</p>
                <p class="meeting-text">会议地址：{{ app.meeting.address }}</p>
                <p class="meeting-text">开始时间：{{ formatShowTime(app.meeting.start_date) }} {{ app.meeting.start_time }}
                </p>
                <div style="text-align: right;">
                    <p style="color:#409EFF;font-size: 16px;margin-bottom: 0px;">{{ app.pay_status }}</p>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
//用户已经报名的会议列表
import '../assets/common/common.css'
import {
    getUserApplications
} from '../api/test'
import {
    formatDate
} from '../utils/date'
import {
    getUserId
} from '../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            user_id: getUserId(),
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        },
        gotoMeetingDetail(app) {
            if (app.pay_status == '待支付') {
                this.$router.push({
                    path: '/pay',
                    query: {
                        application_id: app._id.$id
                    }
                })
                this.$message.success('请先支付')
                return
            }
            this.$router.push({
                path: '/application',
                query: {
                    application_id: app._id.$id
                }
            })
        },
        fetchData() {
            getUserApplications(this.user_id).then((res) => {
                let result = res.data.data
                for (let i = 0; i < result.length; i++) {
                    this.dataList.push(result[i])
                    // this.dataList.push(result[i])
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.app-item {
    cursor: pointer;
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>