<template>
    <div class="view-root">
        <h3 style="text-align: center;">请选择门票</h3>
        <div v-for="(ticket, index) in dataList" :key="index" class="ticket-item">
            <el-card>
                <p class="meeting-name">门票名称：{{ ticket.name }}</p>
                <p class="meeting-text">门票类型：{{ ticket.type }}</p>
                <p class="meeting-text">门票价格：{{ ticket.price }}元</p>
                <div style="text-align: right;">
                    <p class="btn-select" v-if="isViewMeeting()" @click.stop="gotoMeetingForm(ticket)">选择</p>
                    <p v-else class="btn-select" @click.stop="clickShowSelectTicketDialog(ticket)">选择购买</p>
                </div>
            </el-card>
        </div>
        <el-dialog :visible.sync="showSelectTicketDialog" title="提示" width="85%">
            <div>
                <p style="font-size: 18px;margin: 0px;line-height: 28px;">您确定要购买<span style="color: #409EFF;">{{
            selectTicket.name }}</span> 门票吗？，门票价格<span style="color: #409EFF;">{{ selectTicket.price
                        }}</span>元</p>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showSelectTicketDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAndCreateApplication()">确认</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getTickets,
    createMeetingApplication
} from '../api/test'
import '../assets/common/common.css'
import {
    getUserId
} from '../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            source: this.$route.query.source,
            // isViewMeeting:this.source == 'viewMeeting',
            dataList: [],
            selectTicket: {},
            showSelectTicketDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        gotoMeetingForm(ticket) {
            this.$router.push({
                path: '/meetingForm',
                query: {
                    meeting_id: this.meeting_id,
                    ticket_id: ticket._id.$id
                }
            })
        },

        fetchData() {
            getTickets(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        clickShowSelectTicketDialog(ticket) {
            this.selectTicket = ticket
            this.showSelectTicketDialog = true
        },
        isViewMeeting() {
            return this.source == 'viewMeeting'
        },

        confirmAndCreateApplication() {
            createMeetingApplication(this.meeting_id, this.selectTicket._id.$id, getUserId()).then((res) => {
                let result = res.data
                let status = result.data.pay_status
                if (result.code == 0) {
                    if (status == '已支付') {
                        this.$router.replace('/home')
                        this.$message.success('报名成功')
                    } else {
                        this.$router.push({
                            path: '/pay',
                            query: {
                                application_id: result.data._id.$id
                            }
                        })
                        this.$message.success('报名成功,请立即支付')
                    }
                } else {
                    if (status == '已支付') {
                        this.$router.replace('/home')
                        this.$message.success(result.msg)
                    } else {
                        this.$router.push({
                            path: '/pay',
                            query: {
                                application_id: result.data._id.$id
                            }
                        })
                        this.$message.success('已报名,请立即支付')
                    }
                }
            })
        },

        gotoPay(application_id) {
            this.$router.push({
                path: '/pay',
                query: {
                    application_id: application_id
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.btn-select {
    margin: 0px;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-size: 16px;
    padding: 8px 12px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}


.ticket-item {
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>