<script>
import { getUserId } from "../utils/store";
import dayjs from "dayjs";

/** 消息对话记录 */
export default {
  name: "MessageList",
  props: {
    userId: {
      type: String,
      default: getUserId(),
    },
    data: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dayjs,
    };
  },
};
</script>

<template>
  <div class="message-container">
    <div
      v-for="(item, index) in data"
      :key="index"
      :class="item.senderID == userId ? 'rightMessageDiv' : 'leftMessageDiv'"
    >
      <span v-if="item.create_time" class="time">
        {{ dayjs(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}
      </span>
      <div class="message-box">
        {{ item.message }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.message-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  gap: 10px;
  height: calc(100vh - 200px);
}
.leftMessageDiv {
  max-width: 60%;
  width: fit-content;
  margin-left: 5%;
  .message-box {
    font-size: 13px;
    color: #333;
    background-color: #eff3f6;
    line-height: 15px;
    padding: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.rightMessageDiv {
  max-width: 60%;
  margin-right: 5%;
  width: fit-content;
  align-self: flex-end;
  .message-box {
    font-size: 13px;
    background-color: #0080ff;
    line-height: 15px;
    padding: 10px;
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.time {
  color: #999;
  font-size: 12px;
}
</style>
