<template>
    <div class="view-root">
        <h3 style="text-align: center;">会议列表</h3>
        <div v-for="(setting, index) in dataList" :key="index" class="app-item">
            <el-card>
                <p class="meeting-name">会议名称：{{ setting.meeting.name }}</p>
                <div style="display: flex;flex-direction: row;">
                    <p class="meeting-text">会议提醒：</p>
                    <div class="meeting-text">
                        <p>注册完成后提醒：<span style="color: red;"> {{ setting.remind.after_register == true ? '是' : '否'
                                }}</span></p>
                        <p>会议开始前一天提醒： <span style="color: red;">{{ setting.remind.before_one_day == true ? '是' : '否'
                                }}</span></p>
                        <p>会议开始前一小时提醒：<span style="color: red;">{{ setting.remind.before_one_hour == true ?
            '是' : '否' }}</span> </p>
                    </div>
                </div>
                <div style="text-align: right;">
                    <el-button type="primary" size="small" @click="doShowMeetingSettingDialog(setting)">修改</el-button>
                </div>
            </el-card>
        </div>
        <el-dialog :visible.sync="showModifySettingDialog" title="会议提醒设置" width="90%">
            <div>
                <el-form>
                    <el-form-item label="">
                        <el-checkbox v-model="selectedSetting.remind.after_register"
                            label="注册完成后提醒"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="">
                        <el-checkbox v-model="selectedSetting.remind.before_one_day"
                            label="会议开始前一天提醒"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="">
                        <el-checkbox v-model="selectedSetting.remind.before_one_hour"
                            label="会议开始前一小时提醒"></el-checkbox>
                    </el-form-item>
                </el-form>

                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showModifySettingDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveMeetingRemind()">保存</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import '../assets/common/common.css'
import {
    getUserId
} from '../utils/store'
import {
    getUserMeetingSettings,
    updateUserMeetingSettings
} from '../api/test'
import {
    formatDate
} from '../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            dataList: [],
            user_id: getUserId(),
            selectedSetting: {
                remind: {
                        after_register: false,
                        before_one_day: false,
                        before_one_hour: false
                    }
            },
            showModifySettingDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        doShowMeetingSettingDialog(setting) {
            this.selectedSetting = setting
            this.showModifySettingDialog = true
        },

        saveMeetingRemind() {
            let remind = this.selectedSetting.remind
            let params = {
                id: this.selectedSetting._id.$id,
                meeting_id: this.selectedSetting.meeting_id,
                user_id: this.selectedSetting.user_id,
                remind: remind
            }
            updateUserMeetingSettings(params).then((res) => {
                this.$message.success('保存成功')
                this.fetchData()
                this.showModifySettingDialog = false
            })
        },

        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        },
        fetchData() {
            getUserMeetingSettings(this.user_id).then((res) => {
                this.dataList = res.data.data
            })
        }
    }
})
</script>

<style scoped lang="scss">
.app-item {
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>