<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {}
  },
  created () {
    console.log('测试自动部署')
  },
  methods: {}
}
</script>

<style scoped lang="scss">
*{
  padding: 0;
  margin: 0;
}
</style>
