<template>
    <div class="form-container">
        <el-card style="width: 100%;overflow-y: auto;">
            <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;">会议信息</h3>
            <div class="flex-row">
                <p class="form-label">会议名称：</p>
                <p class="form-content">{{ meetingInfo.name }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议主题：</p>
                <p class="form-content">{{ meetingInfo.topic }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议地址：</p>
                <p class="form-content">{{ meetingInfo.address }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">开始日期：</p>
                <p class="form-content">{{ formatShowTime(meetingInfo.start_date) }}</p>
            </div>
            <div class="flex-row" style="margin-top: 25px;">
                <p class="form-label">门票编号：</p>
                <p class="form-content" style="font-weight: bold;">{{ ticket_num }}
                </p>
            </div>
        </el-card>
        <div class="btn-pay" @click="doPay">确认支付</div>
    </div>
</template>
<script>
import {
    getApplicationDetail,
    startWXPay,
    createApplicationOrder,
    getSignPackage
} from '../api/test'
import {
    formatDate
} from '../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            application_id: this.$route.query.application_id,
            meetingInfo: {},
            ticket_num: ""
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        goHome() {
            this.$router.replace('/')
        },

        doPay() {
            //creater order
            createApplicationOrder(this.application_id).then((res) => {
                let order = res.data.data
                this.requestWXPay(order._id.$id)
            })
        },
        requestWXPay(order_id) {
            startWXPay(order_id).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.showWXPay(result.data.timeStamp, result.data.nonceStr, result.data.package, result.data.signType, result.data.paySign)
                } else {
                    this.$message.error(result.msg)
                }
            })
        },
        showWXPay(timestamp, nonceStr, package1, signType, paySign) {
            let that = this
            getSignPackage().then((res) => {
                const result = res.data
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.appId, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.nonceStr, // 必填，生成签名的随机串
                    signature: result.signature, // 必填，签名
                    jsApiList: [
                        'chooseWXPay'
                    ] // 必填，需要使用的JS接口列表
                })
            })
            wx.ready(() => {
                wx.chooseWXPay({
                    timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                    package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: paySign, // 支付签名
                    success: (res) => {
                        // 支付成功后的回调函数
                        that.$message({
                            message: '缴费成功',
                            type: 'success'
                        })
                        that.goHome()
                    }
                })
            })
        },
        fetchData() {
            getApplicationDetail(this.application_id).then((res) => {
                let result = res.data.data
                this.meetingInfo = result.meeting
                this.ticket_num = result.ticket_num
                if (result.pay_status == '已支付') {
                    this.$router.replace('/home')
                    this.$message.success('支付成功')
                }
            })
        },
        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        }
    }
})
</script>
<style scoped lang="scss">
.btn-pay {
    width: 85%;
    cursor: pointer;
    margin: 30px 0px;
    color: white;
    font-size: 18px;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}

.form-container {
    width: 100vw;
    height: 100vh;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.flex-row {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
}

.form-label {
    margin: 0px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.form-content {
    flex: 1;
    margin: 0px;
    font-size: 16px;
    color: #666;
}

.btn-register {
    cursor: pointer;
    margin: 10px 0px;
    color: white;
    font-size: 18px;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}
</style>