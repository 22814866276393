<template>
  <div class="watchContainer">
    <div id="pptx"></div>
    <div id="firstSlide" class="slide">
      <div>
        <div style="margin-bottom: 10px; text-align: center; font-size: 30px">
          {{ sessionName }}
        </div>
        <vue-qr :text="watchUrl" :size="200"></vue-qr>
      </div>
    </div>
    <div class="cover" v-loading="loading">
      <div style="position: relative">
        <canvas id="myCanvas"></canvas>
        <div class="shuiyin" v-if="!loading">
          <div>{{ sessionInfo.meeting?.name }}</div>
          <div>{{ sessionInfo.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSessionPPT, getSessionInfo } from "../api/test";
import html2canvas from "html2canvas";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      currentIndex: 0,
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      url: "",
      client: null,
      watchUrl: "",
      loading: false,
      sessionInfo: {},
      width: 0,
      height: 0,
    };
  },
  mounted() {
    window.onresize = () => {
      this.resize();
    };
    document.addEventListener("visibilitychange", () => {
      // if (document.visibilityState == "hidden") {
      //   //记录页面隐藏时间
      //   let hiddenTime = new Date().getTime();
      // }
      if (!this.client) {
        this.initSocket();
      }
    });
    this.initScale();
    this.fetchData();
    getSessionInfo({
      session_id: this.session_id,
    }).then((res) => {
      if (res.data.code === 0) {
        this.sessionInfo = res.data.data;
      }
    });
  },
  methods: {
    resize() {
      this.initScale();
      this.selectItem();
    },
    initScale() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;

      let ratio = width / height;

      if (ratio > 1280 / 720) {
        let scale = height / 720;
        let canvas = document.getElementById("myCanvas");
        this.width = 1280 * scale;
        this.height = height;
        canvas.style.width = this.width + "px";
        canvas.style.height = this.height + "px";
      } else {
        let scale = width / 1280;
        let canvas = document.getElementById("myCanvas");
        this.width = width;
        this.height = 720 * scale;
        canvas.style.width = this.width + "px";
        canvas.style.height = this.height + "px";
      }
    },
    initSocket() {
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("WebSocket连接成功");
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log(e.data);
        let obj = JSON.parse(e.data);
        if(obj.msg==='登录成功' || obj.msg === '切换页面'){
          this.currentIndex = obj.data;
          this.selectItem();
        }
        
      };

      this.client.onclose = () => {
        console.log("WebSocket连接关闭");
        this.client = null;
      };
    },
    fetchData() {
      this.loading = true;
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.url = res.data.data.ppt_url;
          this.sessionName = res.data.data.session.name;
          this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch?id=${this.id}&session_id=${this.session_id}`;
          $("#pptx").pptxToHtml({
            pptxFileUrl: this.url,
            slideScale: "100%",
            slideMode: false,
            keyBoardShortCut: false,
          });
          setTimeout(() => {
            //添加第一页
            let firstSlide = document.getElementById("firstSlide");
            let area = document.getElementById("all_slides_warpper");
            let first = area.childNodes[0];
            firstSlide.style.width = first.style.width;
            firstSlide.style.height = first.style.height;
            area.insertBefore(firstSlide, first);
            this.initSocket();
            this.loading = false;
          }, 1000);
        }
      });
    },
    selectItem() {
      let dom = document.getElementById("all_slides_warpper");
      let currentItem = dom.childNodes[this.currentIndex];
      html2canvas(currentItem).then((canvas) => {
        let myCanvas = document.getElementById("myCanvas");
        myCanvas.width = canvas.width;
        myCanvas.height = canvas.height;
        let ctx = myCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, 0);
      });
    },
  },
};
</script>

<style lang="scss">
.slide {
  border-radius: 10px !important;
  border: 1px solid #666 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pptx {
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  #all_slides_warpper {
    height: 100% !important;
    .slide {
      margin-bottom: 0;
    }
  }
}
.watchContainer {
  position: relative;
  .cover {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #myCanvas {
    margin: 0 auto;
  }
  .shuiyin {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: #666;
    z-index: 9999999;
    text-align: center;
  }
}
</style>