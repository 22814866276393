<template>
    <div>

    </div>
</template>
<script>
import {
    getUserInfoById
} from '../api/test'
import {
    setUserId
} from '../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            user_id: this.$route.query.user_id
        }
    },
    mounted() {
        getUserInfoById(this.user_id).then((res) => {
            let result = res.data
            if (result != null) {
                setUserId(this.user_id)
                this.$router.replace('/')
            }
        })
    }
})
</script>
