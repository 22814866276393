<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="attachment" @click="actionPreview">
    <div class="attachment-body">
      <div class="attachment-title">
        <!-- Icon 相关 -->
        <template>
          <div class="attachment-title-icon">
            <i v-if="['JPG', 'JPEG', 'PNG', 'GIF'].includes(getFileType)" class="el-icon-picture" style="color: #F4483C"></i>
            <i v-else-if="['PPT', 'PPTX'].includes(getFileType)" class="el-icon-s-platform"></i>
            <i v-else class="el-icon-document"></i>
          </div>
        </template>

        <div>{{data.file_name}}</div>
      </div>
      <div class="attachment-footer">
        <!-- <div class="attachment-footer-type">{{ getFileType }}</div>
        <div class="attachment-footer-dot"></div>
        <div class="attachment-footer-size">未知大小</div> -->
      </div>
    </div>
    <div class="attachment-extra">
      
      <i class="el-icon-arrow-right"></i>

      <!-- 下载暂时不做 -->
      <!-- <div class="attachment-btn" @click.stop="actionDownload">
        <i class="el-icon-download"></i>
      </div> -->
    </div>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
import { ImagePreview } from 'vant';
export default {
  name: '',
  components: {
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
    }
  },

  /* 一.生命周期函数 */
  created () {
  },

  /* 二.监控函数 */
  watch: {
  },

  computed: {
    getFileType () {
      const { attachment_url = '' } = this.data

      const type = attachment_url.split('.').pop()
      return (type || '类型未知').toUpperCase()
    }
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 预览
    actionPreview () {
      const { attachment_url = '' } = this.data
      if (['PNG', 'JPG', 'JPEG', 'GIF'].includes(this.getFileType)) {
        ImagePreview({
          images: [attachment_url],
          closeable: true,
        })
      } else {
        window.open(attachment_url)
      }
    },
    // 下载
    actionDownload () {
      console.log('下载', this.data)
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    doSomething () {

    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx () {

    }
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.attachment{
  min-height: 40px;
  padding: 10px 16px;
  border-bottom: 1px solid #E2E5EA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-body{
    flex: 1;
    margin-right: 10px;
  }
  &-extra{
    flex: 0 0 auto;
  }

  &-title{
    display: flex;
    align-items: center;
    color: #333;
    &-icon{
      margin-right: 4px;
      color: #0080F9;
    }
  }
  &-footer{
    align-items: center;
    margin-top: 10px;
    display: flex;
    color: #666;
    &-dot{
      width: 5px;
      height: 5px;
      background: #999;
      border-radius: 50%;
      margin: 0 6px;
    }
  }

  &-btn{
    cursor: pointer;
    color: #A2B2C1;
    width: 32px;
    height: 32px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #D1D8E0;
  }
}
</style>
