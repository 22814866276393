<template>
    <div class="form-container">
        <h3>欢迎报名{{ meetingInfo.name }}会议</h3>
        <el-card style="width: 100%;height: 100%;overflow-y: auto;">
            <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;">会议信息</h3>
            <div class="flex-row">
                <p class="form-label">会议名称：</p>
                <p class="form-content">{{ meetingInfo.name }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议主题：</p>
                <p class="form-content">{{ meetingInfo.topic }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议地址：</p>
                <p class="form-content">{{ meetingInfo.address }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议规模：</p>
                <p class="form-content">{{ meetingInfo.attend_peoples }}人</p>
            </div>
            <div class="flex-row">
                <p class="form-label"> {{ meetingInfo.type == '单日会议' ? '当天日期：' : '开始日期：' }} </p>
                <p class="form-content">{{ formatShowTime(meetingInfo.start_date) }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">开始时间：</p>
                <p class="form-content">{{ meetingInfo.start_time }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">结束时间：</p>
                <p class="form-content">{{ formatEndTime() }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '多日会议'">
                <p class="form-label">结束日期：</p>
                <p class="form-content">{{ formatShowTime(meetingInfo.end_date) }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">面向观众：</p>
                <p class="form-content">{{ meetingInfo.audiences }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议简介：</p>
                <p class="form-content" v-html="meetingInfo.intro"></p>
            </div>

            <div class="common-btn" @click="gotoCheckHandbooks()">查看会议手册</div>
            <!-- <div class="common-btn" style="margin-top: 20px;" @click="checkContacts()">查看联系方式</div> -->
            <div class="common-btn" style="margin-top: 20px;" @click="gotoSessions()">查看会议日程</div>

            <div class="btn-register" @click="doApplyMeeting">报名参加会议</div>
        </el-card>
    </div>
</template>
<script>
import {
    getMeetingInfo
} from '../api/test'
import {
    formatDate
} from '../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            meetingInfo: {},
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        gotoSessions() {
            this.$router.push({
                path: '/sessions',
                query: {
                    meeting_id: this.meetingInfo._id.$id
                }
            })
        },

        gotoCheckHandbooks() {
            this.$router.push({
                path: '/handbooks',
                query: {
                    meeting_id: this.meetingInfo._id.$id
                }
            })
        },


        fetchData() {
            getMeetingInfo(this.meeting_id).then((res) => {
                this.meetingInfo = res.data.data
            })
        },
        doApplyMeeting() {
            this.$router.push({
                path: '/selectTicket',
                query: {
                    meeting_id: this.meeting_id,
                    source: 'viewMeeting'
                }
            })
        },
        formatEndTime() {
            let start_time = this.meetingInfo.start_time
            let startTimes = start_time.split(':')
            let hours = this.formatTime(startTimes[0]) * 60 * 60
            let mins = this.formatTime(startTimes[1]) * 60
            let seconds = this.formatTime(startTimes[2])
            let startSeconds = hours + mins + seconds
            var totalSeconds = startSeconds + this.meetingInfo.duration

            var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
            totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
            var minute = Math.floor(totalSeconds / 60); // 计算分钟
            totalSeconds %= 60; // 取余得到不足1分钟的秒数

            return this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)

        },
        formatTime(time) {
            return parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
        },
        formatTimeToString(time) {
            if (time >= 10) {
                return time
            }
            return '0' + time
        },
        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        }
    }
})
</script>
<style scoped lang="scss">
.form-container {
    width: 100vw;
    height: 100vh;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.flex-row {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
}

.form-label {
    margin: 0px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.form-content {
    flex: 1;
    margin: 0px;
    font-size: 16px;
    color: #666;
}

.btn-register {
    cursor: pointer;
    margin: 10px 0px;
    color: white;
    font-size: 18px;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}

.ticket-item {
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>