<template>
  <div class="meeting-container" v-show="pageReady">
    <van-nav-bar
      :title="meetingInfo.name"
      left-text="返回"
      left-arrow
      class="meeting-nav-bar"
      @click-left="$router.push('/square')"
    />
    <!-- <el-card style="width: 100%;height: 100%;overflow-y: auto;">
            <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;">会议信息</h3>
            <div class="flex-row">
                <p class="form-label">会议名称：</p>
                <p class="form-content">{{ meetingInfo.name }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议主题：</p>
                <p class="form-content">{{ meetingInfo.topic }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议地址：</p>
                <p class="form-content">{{ meetingInfo.address }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">会议规模：</p>
                <p class="form-content">{{ meetingInfo.attend_peoples }}人</p>
            </div>
            <div class="flex-row">
                <p class="form-label"> {{ meetingInfo.type == '单日会议' ? '当天日期：' : '开始日期：' }} </p>
                <p class="form-content">{{ formatShowTime(meetingInfo.start_date) }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">开始时间：</p>
                <p class="form-content">{{ meetingInfo.start_time }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '单日会议'">
                <p class="form-label">结束时间：</p>
                <p class="form-content">{{ formatEndTime() }}</p>
            </div>
            <div class="flex-row" v-if="meetingInfo.type == '多日会议'">
                <p class="form-label">结束日期：</p>
                <p class="form-content">{{ formatShowTime(meetingInfo.end_date) }}</p>
            </div>
            <div class="flex-row">
                <p class="form-label">面向观众：</p>
                <p class="form-content">{{ meetingInfo.audiences }}</p>
            </div>
            <div class="flex-row" style="flex-direction: column;">
                <p class="form-label">会议简介：</p>
                <p class="form-content" style="margin-top: 10px;" v-html="meetingInfo.intro"></p>
            </div>
            <div class="btn-confirm" @click="gotoHandbooks">查看会议手册</div>
            <div class="btn-confirm" style="margin-top: 15px;" @click="gotoSessions()">查看会议日程</div>
            <div class="btn-confirm" @click="gotoSelectTicketView" style="margin-top: 15px;">报名参加会议</div>
        </el-card> -->

    <div v-if="selectTab == 1" class="main-content">
      <div id="topBgDiv">
        <el-image
          id="topBgImageDiv"
          v-if="meetingInfo.logo_transbg_url || meetingInfo.logo_url"
          :src="meetingInfo.logo_transbg_url || meetingInfo.logo_url"
        >
        </el-image>

        <div id="topShadowDiv"></div>

        <div id="topNameDiv">
          {{ meetingInfo.name }}
        </div>

        <div id="topTopicDiv">
          {{ meetingInfo.topic }}
        </div>
      </div>

      <div id="contentDiv" style="height: calc(100% - 220px)">
        <div id="timeContentDic">
          <!-- 单日会议 -->
          <el-row v-if="meetingInfo.type === '单日会议'">
            <el-col :span="8">
              <div id="beginTimeDiv">
                <div id="beginTopTimeDiv">
                  {{ meetingInfo.show_start_time }}
                </div>

                <div id="beginBottomTimeDiv">
                  {{ formatShowTime(meetingInfo.start_date) }}
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 进行中 -->
              <div id="timeTypeDiv" v-if="meetingInfo.date_status == '进行中'">
                <div id="leftPointDiv"></div>
                <div id="leftPointDiv1"></div>
                <div id="typeShowDiv">{{ meetingInfo.date_status }}</div>
                <div id="rightPointDiv"></div>
                <div id="rightPointDiv1"></div>
              </div>

              <!-- 未开始 -->
              <div id="timeTypeDiv" v-if="meetingInfo.date_status != '进行中'">
                <div id="leftNoPointDiv"></div>
                <div id="leftNoPointDiv1"></div>
                <div id="typeNoShowDiv">{{ meetingInfo.date_status }}</div>
                <div id="rightNoPointDiv"></div>
                <div id="rightNoPointDiv1"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <div id="endTimeDiv">
                <div id="endTopTimeDiv">
                  {{ meetingInfo.show_end_time }}
                </div>

                <div id="endBottomTimeDiv">
                  {{ formatShowTime(meetingInfo.start_date) }}
                </div>
              </div>
            </el-col>
          </el-row>

          <!-- 多日会议 -->
          <el-row v-if="meetingInfo.type === '多日会议'">
            <el-col :span="8">
              <div id="beginTimeDiv">
                <div id="beginTopTimeDiv" style="font-size: 22px">
                  {{ formatShowTime(meetingInfo.start_date, "M月d日") }}
                </div>

                <div id="beginBottomTimeDiv">
                  {{ formatShowTime(meetingInfo.start_date, "yyyy") }}
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 进行中 -->
              <div id="timeTypeDiv" v-if="meetingInfo.date_status == '进行中'">
                <div id="leftPointDiv"></div>
                <div id="leftPointDiv1"></div>
                <div id="typeShowDiv">{{ meetingInfo.date_status }}</div>
                <div id="rightPointDiv"></div>
                <div id="rightPointDiv1"></div>
              </div>

              <!-- 未开始 -->
              <div id="timeTypeDiv" v-if="meetingInfo.date_status != '进行中'">
                <div id="leftNoPointDiv"></div>
                <div id="leftNoPointDiv1"></div>
                <div id="typeNoShowDiv">{{ meetingInfo.date_status }}</div>
                <div id="rightNoPointDiv"></div>
                <div id="rightNoPointDiv1"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <div id="endTimeDiv">
                <div id="endTopTimeDiv" style="font-size: 22px">
                  {{ formatShowTime(meetingInfo.end_date, "M月d日") }}
                </div>

                <div id="endBottomTimeDiv">
                  {{ formatShowTime(meetingInfo.end_date, "yyyy") }}
                </div>
              </div>
            </el-col>
          </el-row>

          <div
            v-if="!meetingInfo.type"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #999;
              font-size: 24px;
            "
          >
            未设置会议类型
          </div>
        </div>

        <div id="addressDiv">
          <el-row>
            <el-col :span="12">
              <el-col :span="3">
                <img id="iconDiv" src="../assets/address.png" />
              </el-col>

              <el-col :span="21">
                <div id="addressNameDiv">
                  {{ meetingInfo.address }}
                </div>

                <div id="addressDetailDiv">
                  {{ meetingInfo.geo_address }}
                </div>
              </el-col>
            </el-col>
            <el-col :span="12">
              <el-col :span="3">
                <img id="iconDiv" src="../assets/peopleNum.png" />
              </el-col>

              <el-col :span="21">
                <div id="addressNameDiv">
                  {{
                    meetingInfo.attend_peoples
                      ? meetingInfo.attend_peoples + "人"
                      : "--" + "人"
                  }}
                </div>
              </el-col>
            </el-col>
          </el-row>
        </div>

        <div id="lineDiv"></div>

        <div id="introTitleDiv">面向观众</div>

        <div id="introDetailDiv">
          {{ meetingInfo.audiences }}
        </div>

        <div id="lineDiv"></div>

        <div id="introTitleDiv">会议简介</div>

        <div
          id="introDetailDiv"
          v-html="meetingInfo.intro"
          style="margin-bottom: 20px"
        ></div>
      </div>
    </div>

    <div v-if="selectTab == 2" class="main-content">
      <Buxucixing :meetingId="meeting_id"></Buxucixing>
    </div>

    <div v-if="selectTab == 3" class="main-content">
      <div v-if="!showDetail" class="handbookDiv">
        <Card1
          @click.native="toDetail(partnerInfo)"
          style="position: relative"
          v-for="(partnerInfo, i) in meetingPartnersList"
          :key="i"
          :data="partnerInfo.partner"
        ></Card1>
      </div>
      <div v-else style="padding: 10px">
        <el-button @click="showDetail = false" size="small" type="primary"
          >返回</el-button
        >
        <el-card style="width: 100%; margin: 10px auto">
          <h3>{{ partnerInfo.company }}</h3>
          <p>{{ partnerInfo.create_time }}</p>
          <div v-html="partnerInfo.intro"></div>
          <img style="max-width: 50%" :src="partnerInfo.logo_url" />
          <p>{{ partnerInfo.master_email }}</p>
          <p>{{ partnerInfo.name }}</p>
          <el-button
            type="primary"
            @click="fetchMessageDetail(partnerInfo._id.$id)"
            >发消息</el-button
          >
        </el-card>
      </div>
    </div>

    <div v-if="selectTab == 4" class="main-content">
      <div
        class="message-list-container"
        style="box-sizing: border-box; padding: 0 10px"
      >
        <h3>对话列表</h3>
        <el-input
          v-model="user_name"
          placeholder="请输入用户名"
          @change="updateUserName"
        ></el-input>
        <el-card
          style="margin: 10px auto"
          class="message-list"
          v-for="(item, index) in userMessagePartners"
          :key="index"
          @click.native="fetchMessageDetail(item.partner_id)"
        >
          {{ item.name }}
        </el-card>
      </div>
    </div>
    <div id="ticketDiv" v-if="showMessageDetail">
      <van-nav-bar
        title="对话详情"
        left-text="返回"
        left-arrow
        fixed
        @click-left="showMessageDetail = false"
      />
      <div class="message-list-detail">
        <!-- 对话消息列表 -->
        <MessageList :data="messageList" />
        <!-- 发送消息 -->
        <div class="send-msg_container">
          <el-input class="send-msg_input" v-model="messageStr" />
          <el-button class="send-msg_btn" @click="sendMessage">发送</el-button>
        </div>
      </div>
    </div>

    <div v-if="selectTab == 5" class="main-content">
      <el-button type="primary" @click="logout()">退出登录</el-button>
    </div>

    <div id="bottomDiv">
      <el-row>
        <el-col :span="6">
          <div @click="tabSelect(1)">
            <img
              id="buttonIconDiv"
              v-if="selectTab == 1"
              src="../assets/infoSelect.png"
            />

            <img
              id="buttonIconDiv"
              v-if="selectTab != 1"
              src="../assets/infoNo.png"
            />

            <div :id="selectTab == 1 ? 'buttonSelectDiv' : 'buttonDiv'">
              尽在掌握
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="tabSelect(2)">
            <img
              id="buttonIconDiv"
              v-if="selectTab == 2"
              src="../assets/richengSelect.png"
            />
            <img
              id="buttonIconDiv"
              v-if="selectTab != 2"
              src="../assets/richengNo.png"
            />

            <div :id="selectTab == 2 ? 'buttonSelectDiv' : 'buttonDiv'">
              不虚此行
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="tabSelect(3)">
            <img
              id="buttonIconDiv"
              v-if="selectTab == 3"
              src="../assets/yongSelect.png"
            />
            <img
              id="buttonIconDiv"
              v-if="selectTab != 3"
              src="../assets/yongNo.png"
            />
            <div :id="selectTab == 3 ? 'buttonSelectDiv' : 'buttonDiv'">
              为我所用
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div @click="tabSelect(4)">
            <img
              id="buttonIconDiv"
              v-if="selectTab == 4"
              src="../assets/piaoSelect.png"
            />
            <img
              id="buttonIconDiv"
              v-if="selectTab != 4"
              src="../assets/piaoNo.png"
            />
            <div :id="selectTab == 4 ? 'buttonSelectDiv' : 'buttonDiv'">
              有求必应
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="6">
          <div @click="tabSelect(5)">

            <img
              id="buttonIconDiv"
              v-if="selectTab==4"
              src="../assets/piaoSelect.png"
            >
            <img
              id="buttonIconDiv"
              v-if="selectTab!=4"
              src="../assets/piaoNo.png"
            >
            <div :id="selectTab==5 ? 'buttonSelectDiv':'buttonDiv' ">
              我的设置
            </div>
          </div>
        </el-col> -->
      </el-row>
    </div>

    <el-dialog :visible.sync="showSelectTicketDialog" title="提示" width="85%">
      <div>
        <p style="font-size: 18px; margin: 0px; line-height: 28px">
          您确定要购买<span style="color: #409eff">{{
            selectTicket.name
          }}</span>
          门票吗？，门票价格<span style="color: #409eff">{{
            selectTicket.price
          }}</span
          >元
        </p>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showSelectTicketDialog = false">取 消</el-button>
            <el-button type="primary" @click="confirmAndCreateApplication()"
              >确认</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "../utils/date";
import "../assets/common/module1.css";
import MessageList from "../components/MessageList.vue";
import Card1 from "../components/Card1.vue";
import Buxucixing from "../components/Buxucixing.vue";
import dayjs from "dayjs";
import {
  getMeetingInfo,
  checkUserMeetingApplication,
  getSessions,
  getMeetingHandbooks,
  createMeetingApplication,
  getUserMeetingApplications,
  getUserMessagePartners,
  getServiceMessagesByDialogue,
  sendServiceMessage,
  getMeetingPartners,
  getUserInfoById,
  updateUserName,
  getPartnerInfo,
  userCheckIn,
} from "../api/test";
import { getUserId, removeUserId } from "../utils/store";

export default {
  name: "index",
  components: {
    MessageList,
    Card1,
    Buxucixing,
  },
  data() {
    return {
      dayjs,
      pageReady: false,
      meeting_id: this.$route.query.meeting_id,
      meetingInfo: {},
      dataList: [],
      selectTab: 1,
      searchKey: "",
      scheduleType: "detail", //easy or detail
      bgColorArr: ["#E94933", "#59B1CF", "#4C7AFE", "#9D53EF", "#EF9A53"],
      handbooksDataList: [],
      ticketsDataList: [],
      showSelectTicketDialog: false,
      ticketSelectType: "all", // all or my
      selectTicket: {},
      userApps: [],

      meetingPartnersList: [],
      showMessageDetail: false,
      // 对话列表
      userMessagePartners: [],
      // 主持人状态
      presenterStatus: false,
      client: null,
      client_msg: null,
      partner_id: undefined,
      user_id: getUserId(),
      // 对话内容详情
      messageList: [],
      messageStr: "",
      showDetail: false,
      user_name: "",
    };
  },
  mounted() {
    // 静默签到
    userCheckIn({
      meeting_id: this.meeting_id,
      user_id: getUserId(),
    })
    this.fetchData();
    document.addEventListener("visibilitychange", () => {
      if (!this.client_msg) {
        this.initSocket();
      }
    });
    if (!this.client_msg) {
      this.initSocket();
    }
    this.getUser();
  },
  methods: {
    getUser() {
      const user_id = getUserId();
      getUserInfoById(user_id).then((res) => {
        this.user_name = res.data.name;
      });
    },
    updateUserName() {
      updateUserName({
        id: getUserId(),
        name: this.user_name,
      }).then((res) => {
        this.$message.success("修改成功");
      });
    },
    toDetail(data) {
      console.log(1111111111, data);
      this.partnerInfo = data.partner;
      this.showDetail = true;
    },
    logout() {
      removeUserId();
      this.$router.replace("/login");
      this.$message.success("退出登录成功");
    },

    gotoSessions() {
      this.$router.push({
        path: "/sessions",
        query: {
          meeting_id: this.meeting_id,
        },
      });
    },

    gotoHandbooks() {
      this.$router.push({
        path: "/handbooks",
        query: {
          meeting_id: this.meeting_id,
        },
      });
    },

    gotoPay(app) {
      if (app.pay_status == "已支付") {
      } else {
        this.$router.push({
          path: "/pay",
          query: {
            application_id: app._id.$id,
          },
        });
      }
    },

    buyTicket(ticket) {
      checkUserMeetingApplication(getUserId(), this.meeting_id).then((res) => {
        let result = res.data.data;
        if (result == null) {
          this.selectTicket = ticket;
          this.showSelectTicketDialog = true;
        } else {
          let pay_status = result.pay_status;
          if (pay_status == "待支付") {
            this.$message.success("您已经报名过此会议，但还未支付，请立即支付");
            this.$router.push({
              path: "/pay",
              query: {
                application_id: result._id.$id,
              },
            });
          } else {
            this.$message.success("您已经报名过此会议，无需再报名");
          }
        }
      });
    },
    confirmAndCreateApplication() {
      createMeetingApplication(
        this.meeting_id,
        this.selectTicket._id.$id,
        getUserId()
      ).then((res) => {
        let result = res.data;
        let status = result.data.pay_status;
        if (result.code == 0) {
          if (status == "已支付") {
            this.$router.replace("/home");
            this.$message.success("报名成功");
          } else {
            this.$router.push({
              path: "/pay",
              query: {
                application_id: result.data._id.$id,
              },
            });
            this.$message.success("报名成功,请立即支付");
          }
        } else {
          if (status == "已支付") {
            this.$router.replace("/home");
            this.$message.success(result.msg);
          } else {
            this.$router.push({
              path: "/pay",
              query: {
                application_id: result.data._id.$id,
              },
            });
            this.$message.success("已报名,请立即支付");
          }
        }
      });
    },
    gotoSelectTicketView() {
      //检查是否已经报名过
      checkUserMeetingApplication(getUserId(), this.meeting_id).then((res) => {
        let result = res.data.data;
        if (result == null) {
          this.$router.push({
            path: "selectTicket",
            query: {
              meeting_id: this.meeting_id,
            },
          });
        } else {
          let pay_status = result.pay_status;
          if (pay_status == "待支付") {
            this.$message.success("您已经报名过此会议，但还未支付，请立即支付");
            this.$router.push({
              path: "/pay",
              query: {
                application_id: result._id.$id,
              },
            });
          } else {
            this.$message.success("您已经报名过此会议，无需再报名");
          }
        }
      });
    },

    async fetchData() {
      const meetingInfoRes = await getMeetingInfo(this.meeting_id);
      if (meetingInfoRes.data.code !== 0) {
        return this.$message.error(meetingInfoRes.data.msg);
      }
      const { check_ticket } = meetingInfoRes.data.data;
      const user_id = getUserId();
      if (!user_id) {
        switch (check_ticket) {
          case "YES":
            return this.$router.push({ name: "login" });
          case "NO":
            return this.$router.push({
              path: "/login",
              // name: "autoLogin",
              query: {
                backUrl: encodeURIComponent(location.href),
              },
            });
        }
      }
      this.pageReady = true;
      this.meetingInfo = meetingInfoRes.data.data;
      let start_time = this.meetingInfo.start_time;

      if (start_time) {
        let startTimes = start_time.split(":");

        let show_start_time = startTimes[0] + ":" + startTimes[1];
        this.meetingInfo.show_start_time = show_start_time;

        let hours = this.formatTime(startTimes[0]) * 60 * 60;
        let mins = this.formatTime(startTimes[1]) * 60;
        let seconds = this.formatTime(startTimes[2]);
        let startSeconds = hours + mins + seconds;
        var totalSeconds = startSeconds + this.meetingInfo.duration;

        var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
        totalSeconds %= 60 * 60; // 取余得到不足1小时的秒数
        var minute = Math.floor(totalSeconds / 60); // 计算分钟
        totalSeconds %= 60; // 取余得到不足1分钟的秒数

        let show_end_time =
          this.formatTimeToString(hour) + ":" + this.formatTimeToString(minute);

        this.meetingInfo.show_end_time = show_end_time;

        const timestamp = new Date().getTime();

        //判断时候结束
        var endTimeDate =
          this.meetingInfo.start_date + this.meetingInfo.duration;

        if (this.meetingInfo.start_date > timestamp / 1000) {
          this.meetingInfo.date_status = "未开始";
        } else if (endTimeDate < timestamp / 1000) {
          this.meetingInfo.date_status = "已结束";
        } else {
          this.meetingInfo.date_status = "进行中";
        }
      } else {
        this.meetingInfo.show_start_time = "--";
        let show_end_time = "--";
        this.meetingInfo.show_end_time = show_end_time;
        this.meetingInfo.date_status = "未开始";
      }

      getSessions(this.meeting_id).then((res) => {
        let result = res.data.data;
        result.map((item) => {
          let start_time = item.start_time;
          let startTimes = start_time.split(":");
          let hours = this.formatTime(startTimes[0]) * 60 * 60;
          let mins = this.formatTime(startTimes[1]) * 60;
          // let seconds = this.formatTime(startTimes[2])
          let seconds = 0;
          let startSeconds = hours + mins + seconds;
          var totalSeconds = startSeconds + item.duration * 60;
          var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
          totalSeconds %= 60 * 60; // 取余得到不足1小时的秒数
          var minute = Math.floor(totalSeconds / 60); // 计算分钟
          totalSeconds %= 60; // 取余得到不足1分钟的秒数
          // let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)
          let show_end_time =
            this.formatTimeToString(hour) +
            ":" +
            this.formatTimeToString(minute);
          item.show_end_time = show_end_time;
          item.startSeconds = startSeconds;
          item.show_start_time = startTimes[0] + ":" + startTimes[1];

          return item;
        });
        result.sort((a, b) => {
          if (a.startSeconds > b.startSeconds) {
            return 1;
          }
          return -1;
        });
        let array1 = result.filter(
          (item) => item.session_group_id && item.session_group_id != ""
        ); //先过滤掉有group的
        let array2 = result.filter(
          (item) => !item.session_group_id || item.session_group_id == ""
        ); //再获取没有group的
        let array3 = array1.concat(array2);
        this.dataList = array3;
      });

      getMeetingHandbooks(this.meeting_id).then((res) => {
        this.handbooksDataList = res.data.data;
      });
      getMeetingPartners(this.meeting_id).then((res) => {
        // 数据前端聚合
        this.meetingPartnersList = res.data.data;
      });
    },
    formatEndTime() {
      let start_time = this.meetingInfo.start_time;
      let startTimes = start_time.split(":");
      let hours = this.formatTime(startTimes[0]) * 60 * 60;
      let mins = this.formatTime(startTimes[1]) * 60;
      let seconds = this.formatTime(startTimes[2]);
      let startSeconds = hours + mins + seconds;
      var totalSeconds = startSeconds + this.meetingInfo.duration;

      var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
      totalSeconds %= 60 * 60; // 取余得到不足1小时的秒数
      var minute = Math.floor(totalSeconds / 60); // 计算分钟
      totalSeconds %= 60; // 取余得到不足1分钟的秒数
      return (
        this.formatTimeToString(hour) + ":" + this.formatTimeToString(minute)
      );

      // return this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)
    },
    formatTime(time) {
      return (
        parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
      );
    },
    formatTimeToString(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },

    formatShowTime(time, type = "yyyy-MM-dd") {
      return formatDate(new Date(time * 1000), type);
    },
    formatShowDate(time) {
      var weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      var date = new Date(time * 1000);
      var dayOfWeek = date.getDay();

      return (
        formatDate(new Date(time * 1000), "yyyy年MM月dd日") +
        "(" +
        weekdays[dayOfWeek] +
        ")"
      );
    },
    changeTicket(row) {
      this.ticketSelectType = row;

      if (row == "my") {
        getUserMeetingApplications(getUserId(), this.meeting_id).then((res) => {
          let result = res.data.data;
          this.userApps = [];
          for (let i = 0; i < result.length; i++) {
            this.userApps.push(result[i]);
            // this.dataList.push(result[i])
          }
        });
      }
    },
    tabSelect(index) {
      this.selectTab = index;
      if (index == 4) {
        // 获取对话消息列表
        this.fetchMessage();
      }
    },
    async fetchMessage() {
      const res = await getUserMessagePartners(this.user_id);
      if (res.data.code !== 0) return;
      const data = res.data.data;
      this.userMessagePartners = data;
    },
    // 对话详情
    fetchMessageDetail(partnerId) {
      this.partner_id = partnerId;
      this.showMessageDetail = true;
      const talkers = [partnerId, this.user_id];
      getServiceMessagesByDialogue(talkers).then((res) => {
        this.messageList = res.data.data;
      });
    },
    initSocket() {
      if (!this.user_id) {
        return;
      }
      this.client_msg = new WebSocket("wss://team-chat.learningfirst.cn:9021");
      this.client_msg.onopen = () => {
        console.log("消息WebSocket连接成功");
        this.status = true;
        this.client_msg.send(
          JSON.stringify({
            type: "login",
            userId: this.user_id,
          })
        );
      };
      this.client_msg.onmessage = (e) => {
        let obj = JSON.parse(e.data);
        if (obj.msg === "客服消息") {
          this.handleKefuMsgReceived(obj);
        }
      };
      this.client_msg.onclose = () => {
        console.log("消息WebSocket连接关闭");
        this.$message.error("消息WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };
    },
    async handleKefuMsgReceived(obj) {
      let senderID = obj.senderID;
      let partnerResult = await getPartnerInfo(senderID);
      let messageStr = obj.data;
      let messageData = [];
      messageData["message"] = messageStr;
      messageData["senderID"] = senderID;
      messageData["sender_name"] = partnerResult.data.name;
      this.messageList.push(messageData);
    },
    sendMessage() {
      if (this.partner_id != "") {
        var messageToSend = JSON.stringify({
          type: "serviceMessage",
          msg: "客服消息",
          pptId: this.meeting_id,
          message: this.messageStr,
          senderID: this.user_id,
          receiveID: this.partner_id,
          create_time: new Date().getTime() / 1000,
        });
        this.client_msg && this.client_msg.send(messageToSend);

        sendServiceMessage(messageToSend);
        console.log(messageToSend);
        this.messageList.push(JSON.parse(messageToSend));
        console.log(this.messageList);
      } else {
        this.$message.warning("抱歉，本场议程没有品牌方官方客服");
      }
      this.messageStr = "";
    },
  },
};
</script>

<style scoped lang="scss">
.empty-list {
  text-align: center;
  margin: 20px;
  color: #999;
}

.btn-confirm {
  cursor: pointer;
  margin: 10px 0px;
  color: white;
  font-size: 18px;
  padding: 10px 0px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  background-color: #409eff;
}

.meeting-container {
  width: 100vw;
  height: 100vh;
  // padding: 10px 15px;
  // background-color: red;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.flex-row {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.form-label {
  margin: 0px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.form-content {
  flex: 1;
  margin: 0px;
  font-size: 16px;
  color: #666;
  line-height: 24px;
}

.send-msg_container {
  width: 100%;
  height: 10%;
}

.send-msg_input {
  width: 70%;
  margin-left: 5%;
}

.send-msg_btn {
  margin-left: 5%;
  width: 15%;
}

.main-content {
  width: 100%;
  flex: 1;
  overflow: auto;
}

.handbookDiv {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-list-container {
  min-height: calc(100vh - 80px);
  width: 100vw;
}
.message-list-detail {
  margin-top: 46px;
}
.meeting-nav-bar {
  width: 100%;
}
#ticketDiv {
  position: fixed;
  z-index: 2;
}
</style>
