<template>
    <div class="view-root">
        <h3 style="text-align: center;">日程列表</h3>
        <div v-for="(session, index) in dataList" :key="index" class="app-item">
            <el-card>
                <p class="meeting-name">日程名称：{{ session.name }}</p>
                <p class="meeting-text">日程类型：{{ session.type }}</p>
                <p class="meeting-text">日程日期：{{ formatShowDate(session.date) }}</p>
                <p class="meeting-text">开始时间：{{ session.start_time }}</p>
                <p class="meeting-text">总时长：{{ session.duration }}分钟</p>
                <p class="meeting-text">结束时间：{{ session.show_end_time }}</p>
                <p class="meeting-text">会议房间：{{ session.meetingRoom ? session.meetingRoom.name : '' }}</p>
                <p v-if="session.guests && session.guests.length > 0" class="meeting-text">会议嘉宾：</p>
                <el-collapse v-if="session.guests && session.guests.length > 0">
                    <el-collapse-item v-for="(guest, guestIndex) in session.guests" :key="guestIndex"
                        :title="'嘉宾' + (guestIndex + 1)" :name="(index + 1) + '-' + (guestIndex + 1)">
                        <div class="meeting-text">
                            <p>{{ '姓名：' + guest.name }}</p>
                            <p>{{ '角色：' + guest.role }}</p>
                            <div style="display: flex;width: 100%;flex-wrap: wrap;">
                                <p class="meeting-text">头衔：</p>
                                <div style="display: flex;flex-wrap: wrap;margin-top: 10px;gap: 5px;">
                                    <el-tag v-for="(title, titleIndex) in guest.titles" :key="titleIndex"
                                        type="success">{{ title
                                        }}</el-tag>
                                </div>
                            </div>
                            <p>{{ '所在公司：' + guest.company }}</p>
                            <div style="display: flex;">
                                <p class="meeting-text">头像：</p>
                                <img :src="guest.avatar_url"
                                    style="width: 72px;height: 72px;object-fit: cover;margin-left: 10px;">
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </div>
    </div>
</template>
<script>
import '../assets/common/common.css'
import {
    getSessions
} from '../api/test'
import {
    formatDate
} from '../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        checkGuests(id) {
            this.$router.push({
                path: '/sessionGuests',
                query: {
                    session_id: id
                }
            })
        },

        fetchData() {
            getSessions(this.meeting_id).then((res) => {
                let result = res.data.data
                result.map((item) => {
                    let start_time = item.start_time
                    let startTimes = start_time.split(':')
                    let hours = this.formatTime(startTimes[0]) * 60 * 60
                    let mins = this.formatTime(startTimes[1]) * 60
                    // let seconds = this.formatTime(startTimes[2])
                    // let startSeconds = hours + mins + seconds
                    let startSeconds = hours + mins 
                    var totalSeconds = startSeconds + item.duration * 60
                    var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
                    totalSeconds %= (60 * 60); // 取余得到不足1小时的秒数
                    var minute = Math.floor(totalSeconds / 60); // 计算分钟
                    totalSeconds %= 60; // 取余得到不足1分钟的秒数
                    let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) 

                    // let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)
                    item.show_end_time = show_end_time
                    item.startSeconds = startSeconds
                    return item
                })
                result.sort((a, b) => {
                    if (a.startSeconds > b.startSeconds) {
                        return 1
                    }
                    return -1
                })
                let array1 = result.filter((item) => item.session_group_id && item.session_group_id != '')//先过滤掉有group的
                let array2 = result.filter((item) => !item.session_group_id || item.session_group_id == '') //再获取没有group的
                let array3 = array1.concat(array2)
                this.dataList = array3
            })
        },
        formatTime(time) {
            return parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
        },
        formatTimeToString(time) {
            if (time >= 10) {
                return time
            }
            return '0' + time
        },
        formatShowDate(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        },
    }
})
</script>
<style scoped lang="scss">
.app-item {

    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>