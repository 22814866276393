<template>
    <div class="view-root">
        <h3 style="text-align: center;">嘉宾列表</h3>
        <div v-for="(guest, index) in dataList" :key="index" class="app-item">
            <el-card>
                <p class="meeting-name">嘉宾姓名：{{ guest.name }}</p>
                <p class="meeting-text">嘉宾角色：{{ guest.role }}</p>
                <div style="display: flex;width: 100%;flex-wrap: wrap;">
                    <p class="meeting-text">嘉宾头衔：</p>
                    <div style="display: flex;flex-wrap: wrap;margin-top: 10px;">
                        <el-tag v-for="(title, titleIndex) in guest.titles" :key="titleIndex" type="success">{{ title
                            }}</el-tag>
                    </div>
                </div>
                <p class="meeting-text">所在公司：{{ guest.company }}</p>
                <div style="display: flex;">
                    <p class="meeting-text">嘉宾头像：</p>
                    <img :src="guest.avatar_url" style="width: 72px;height: 72px;object-fit: cover;margin-left: 10px;">
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import {
    getSessionDetail
} from '../api/test'
export default ({
    name: 'index',
    data() {
        return {
            session_id: this.$route.query.session_id,
            dataList: []
        }
    },
    mounted() {
        this.fethData()
    },
    methods: {
        fethData() {
            getSessionDetail(this.session_id).then((res) => {
                this.dataList = res.data.data.guests
            })
        }
    }
})
</script>
<style scoped lang="scss">
.app-item {
    cursor: pointer;
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>