<template>
  <div class="pdfContainer">
    <div class="onlineStatus">
      <div>当前状态：</div>
      <div class="statusIcon" :class="status ? 'green' : 'red'"></div>
      <div>{{ status ? "在线" : "离线" }}</div>
      <!-- <div style="margin-left: 26px">演示者状态：</div>
      <div
        class="statusIcon"
        :class="status && presenterStatus ? 'green' : 'red'"
      ></div>
      <div>{{ status && presenterStatus ? "在线" : "离线" }}</div> -->
    </div>
    <div style="position: relative" id="show">
      <div id="firstSlide" v-if="currentIndex === 0">
        <div>
          <div style="text-align: center; font-size: 24px">
            {{ sessionName }}
          </div>
          <vue-qr :text="watchUrl" :size="140"></vue-qr>
        </div>
      </div>
      <canvas
        @click="actionPreview(curPageImageUrl)"
        id="pdf-render"
        :key="canvasKey"
      ></canvas>
      <!-- <div class="shuiyin">
        <div>({{ this.currentIndex + 1 }}/{{ total + 1 }})</div>
        <div>{{ sessionInfo.meeting?.name }}</div>
        <div>{{ sessionInfo.name }}</div>
      </div> -->
    </div>

    <div class="meeting-info">
      <div class="meeting-info-header">
        <div class="meeting-info-header-title">{{ "" }}</div>
        <div class="meeting-info-header-extra">
          ({{ this.currentIndex + 1 }}/{{ total + 1 }})
        </div>
      </div>
      <div class="meeting-info-desc">
        {{ sessionInfo.meeting?.name }}
        <span style="margin-left: 4px">{{ sessionInfo.name }}</span>
      </div>
    </div>

    <div class="noteContainer">
      {{ notes[currentIndex] ? notes[currentIndex] : "此页暂无演示者笔记" }}
    </div>
    <div class="btnGroup">
      <div class="begin" v-if="!isBeginSpeech">
        <el-button type="primary" @click="beginSpeech()">开始演讲</el-button>
      </div>
      <div v-else>
        <div style="text-align: center; margin: 2px 0">
          {{ showSpeechTime }}
        </div>
        <div style="margin-bottom: 10px">
          <el-input v-model="ip" placeholder="电脑端IP地址"></el-input>
        </div>
        <el-button
          type="primary"
          style="width: 100px; height: 50px; font-size: 16px"
          @click="com"
          >{{ showCom ? "结束校准" : "校准" }}</el-button
        >
        <el-button
          type="primary"
          style="width: 100px; height: 50px; font-size: 16px"
          @click="beginSpeech()"
          >重新演讲</el-button
        >
        <el-button
          type="primary"
          style="width: 100px; height: 50px; font-size: 16px"
          @click="last"
          >上一页</el-button
        >
        <el-button
          type="primary"
          @click="next"
          style="width: 100px; height: 50px; font-size: 16px"
          >下一页</el-button
        >
      </div>
      <div
        v-if="showCom"
        style="display: flex; align-items: center; margin-top: 10px"
      >
        <div>
          <span>手机端翻页</span>
          <el-button
            type="primary"
            style="width: 100px; height: 50px; font-size: 16px"
            @click="lastByWeb"
            >上一页</el-button
          >
          <el-button
            type="primary"
            @click="nextByWeb"
            style="width: 100px; height: 50px; font-size: 16px"
            >下一页</el-button
          >
        </div>
        <div style="margin-left: 10px">
          <span>电脑端翻页</span>
          <el-button
            type="primary"
            style="width: 100px; height: 50px; font-size: 16px"
            @click="lastByControl"
            >上一页</el-button
          >
          <el-button
            type="primary"
            @click="nextByControl"
            style="width: 100px; height: 50px; font-size: 16px"
            >下一页</el-button
          >
        </div>
      </div>
    </div>

    <!-- <div>
      <button @click="vibrate(100)">轻微震动</button>
      <button @click="vibrate(300)">中等震动</button>
      <button @click="vibrate(500)">强烈震动</button>
      <button @click="cancelVibrate()">取消震动</button>
    </div> -->
  </div>
</template>

    <script>
import axios from "axios";
import VueQr from "vue-qr";
import { ImagePreview } from "vant";
import {
  getPPTNotes,
  getSessionInfo,
  getSessionPPT,
  setShowFile,
  getShowFile,
} from "../api/test";
const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      speechTime: 0,
      curPageImageUrl: "",
      imgInstance: null,
      isBeginSpeech: false,
      showSpeechTime: "00:00",
      currentIndex: null,
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      color: "#000000",
      url: "",
      client: null,
      watchUrl: "",
      loading: false,
      presentUrl: "",
      codeVisible: false,
      pdfDoc: null,
      total: 0,
      width: 0,
      height: 0,
      canvasKey: 0,
      sessionInfo: {},
      notes: [],
      presenterStatus: false,
      status: false,
      ip: "",
      hiddenTime: 0,
      showCom: false,
    };
  },
  mounted() {
    this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch-pdf?id=${this.id}&session_id=${this.session_id}`;
    this.fetchData();

    document.addEventListener("visibilitychange", () => {
      this.handleVisibilityChange();
    });

    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
    // this.getPPTNotes();
    getSessionInfo({
      session_id: this.session_id,
    }).then((res) => {
      if (res.data.code === 0) {
        this.sessionInfo = res.data.data;
        console.log("🚀 ~ mounted ~ this.sessionInfo:", this.sessionInfo);
        this.speechTime = res.data.data.duration;
      }
    });
  },
  methods: {
    handleVisibilityChange() {
      //判断是否熄屏
      if (document.visibilityState === "hidden") {
        //熄屏时记录熄屏时间戳  hiddenTime要在data中定义
        this.hiddenTime = new Date().getTime();
      } else {
        //当页面屏幕点亮记录亮屏时间戳
        let visibleTime = new Date().getTime();
        //判断亮屏时间戳减去熄屏时间戳如果大于10秒就调用处理函数
        if ((visibleTime - this.hiddenTime) / 1000 > 10) {
          this.reconnectWebSocket();
        }
      }
    },
    //处理函数
    reconnectWebSocket() {
      //在重启websoket前先关闭旧链接
      if (this.client) {
        this.client.close(); // 关闭旧连接
      }
      this.fetchData(); // 初始化新连接
    },
    beginSpeech() {
      this.isBeginSpeech = true;
      clearInterval(this.countDownInternal);

      var timeCha = this.speechTime * 60;

      let currentTime = new Date().getTime() / 1000;
      this.client &&
        this.client.send(
          JSON.stringify({
            type: "startPPT",
            pptId: this.id,
            time: currentTime + this.speechTime * 60,
          })
        );
      this.countDownInternal = setInterval(() => {
        timeCha--;
        if (timeCha < 0) {
          this.showSpeechTime = "00:00";
          clearInterval(this.countDownInternal);
          resolve(true);
        } else {
          this.formatShowTime(timeCha);
        }
      }, 1000);

      // setShowFile({
      //   ppt_id: this.id,
      //   session_id: this.session_id,
      // }).then((res) => {
      //   getShowFile();
      // });
    },
    actionPreview(imgUrl) {
      if (!imgUrl) return;
      this.imgInstance = ImagePreview({
        images: [imgUrl],
        closeable: true,
      });
    },
    formatShowTime(time) {
      // console.log('倒计时' + time)
      var theTime = time;
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时
      if (theTime > 60) {
        //超过1分钟
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60); //剩余秒数
        if (theTime1 > 60) {
          //超过60分钟
          theTime2 = parseInt(theTime1 / 60); //多少小时
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      console.log(theTime1, theTime);
      this.showSpeechTime =
        this.formatTime1(theTime1) + " : " + this.formatTime1(theTime);
    },
    formatTime1(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },
    vibrate(duration) {
      // 单参数模式：持续毫秒
      navigator.vibrate(duration);
    },
    cancelVibrate() {
      // 取消震动
      navigator.vibrate(0);
    },
    resize() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
    },
    getPPTNotes() {
      getPPTNotes({
        ppt_id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          this.notes = res.data.data ? res.data.data : [];
        }
      });
    },
    initSocket() {
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("WebSocket连接成功");
        this.status = true;
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
            isPresenter: true,
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log(e.data);
        let obj = JSON.parse(e.data);
        if (obj.msg === "主持人状态") {
          this.presenterStatus = obj.data;
        } else if (obj.msg === "登录成功" || obj.msg === "切换页面") {
          this.currentIndex = obj.data;
          this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        }
      };
      this.client.onclose = () => {
        console.log("WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };
    },
    fetchData() {
      this.loading = true;
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.type === "PPT") {
            this.url = res.data.data.pdf_url;
          } else {
            this.url = res.data.data.ppt_url;
          }
          this.notes = res.data.data.notes ? res.data.data.notes : [];
          this.sessionName = res.data.data.session.name;
          PDFJS.getDocument(this.url).promise.then((doc) => {
            this.pdfDoc = doc;
            this.total = doc.numPages;
            this.initSocket();
          });
        }
      });
    },
    getPdfPage(number) {
      this.canvasKey++;
      this.pdfDoc.getPage(number).then((page) => {
        const viewport = page.getViewport();
        const canvas = document.getElementById("pdf-render");
        const context = canvas.getContext("2d");
        canvas.width = viewport.viewBox[2];
        canvas.height = viewport.viewBox[3];
        viewport.width = viewport.viewBox[2];
        viewport.height = viewport.viewBox[3];

        let pdfWidth = viewport.width;
        let pdfHeight = viewport.height;
        if (pdfWidth > this.width || pdfHeight > this.height) {
          let scale = Math.min(this.width / pdfWidth, this.height / pdfHeight);
          pdfWidth *= scale;
          pdfHeight *= scale;
        }

        canvas.style.width = pdfWidth + "px";
        canvas.style.height = pdfHeight + "px";

        let renderContext = {
          canvasContext: context,
          viewport: viewport,
          // 这里transform的六个参数，使用的是transform中的Matrix(矩阵)
          transform: [1, 0, 0, -1, 0, viewport.height],
        };
        // 进行渲染
        const renderTask = page.render(renderContext);
        renderTask.promise.finally(() => {
          canvas.toBlob((blob) => {
            this.curPageImageUrl = URL.createObjectURL(blob);
            if (this.imgInstance?.opened) {
              this.imgInstance.images = [this.curPageImageUrl];
            }
          });
        });
      });
    },
    com() {
      this.showCom = !this.showCom;
    },
    lastByWeb() {
      if (this.currentIndex > 0) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex - 1,
          })
        );
      }
    },
    nextByWeb() {
      if (this.currentIndex < this.total) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex + 1,
          })
        );
      }
    },
    nextByControl() {
      let ip = this.ip.replace("https://", "");
      ip = ip.replace("http://", "");
      let port = ip.split(":")[1];
      ip = ip.split(":")[0];
      let reg = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
      if (reg.test(ip)) {
        axios
          .get(`http://${ip}:${port ? port : 5000}/press?key=down`)
          .then((res) => {
            console.log(res);
          });
      }
    },
    lastByControl() {
      let ip = this.ip.replace("https://", "");
      ip = ip.replace("http://", "");
      let port = ip.split(":")[1];
      ip = ip.split(":")[0];
      let reg = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
      if (reg.test(ip)) {
        axios
          .get(`http://${ip}:${port ? port : 5000}/press?key=up`)
          .then((res) => {
            console.log(res);
          });
      }
    },
    next() {
      if (this.currentIndex < this.total) {
        this.nextByWeb();
        this.nextByControl();
      }
    },
    last() {
      if (this.currentIndex > 0) {
        this.lastByWeb();
        this.lastByControl();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meeting-info {
  background: #f7fbff;
  border: 1px solid #e9edf0;
  padding: 8px 16px;
  font-size: 12px;
  color: #0181f9;
  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      width: 100%;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-extra {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }
  &-desc {
    margin-top: 6px;
    font-size: #0482f9;
  }
}

.pdfContainer {
  width: 100%;
  height: 100%;
  .pdf {
    width: 1280px;
    height: 720px;
  }
  #firstSlide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #666;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
  }
  .shuiyin {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: #666;
    z-index: 9999999;
    text-align: center;
  }
  .noteContainer {
    min-height: 50px;
    padding: 5px;
  }
  .btnGroup {
    position: fixed;
    right: 10px;
    bottom: 120px;
  }
  .onlineStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .statusIcon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .red {
      background: red;
    }
    .green {
      background: green;
    }
  }
}
</style>