<template>
  <div class="login-container">
    <img src="../../assets/logo.png" style="width: 100px; height: auto; margin-top: 70px" />
    <p class="login-hint">请输入手机号后登录</p>
    <el-input v-model="user_name" placeholder="请输入手机号码" style="margin-top: 25px" />
    <div style="display: flex; align-items: center; margin-top: 15px; width: 100%">
      <el-input v-model="password" placeholder="请输入验证码" style="flex: 1; padding-right: 10px; box-sizing: border-box" />
      <p class="btn-login" :class="{ disabled: isSending }" @click="doSend" style="width: 100px; font-size: 15px; margin: 0px; border-radius: 5px">
        {{isSending ? time : '发送验证码'}}
      </p>
    </div>
    <p class="btn-login" @click="doLogin">登录</p>
    <p class="agreement" v-if="userAgreement">登录使用参好会服务即表示您同意<span class="agreement-link" @click="actionShowProtocol">《{{userAgreement.name}}》</span></p>

    <van-popup v-model="isShowAgreementPopup" round closeable>
      <div class="agreement-header">{{ userAgreement.name }}</div>
      <div v-if="userAgreement" class="agreement-container">
        <div v-html="userAgreement.content"></div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getPhoneCode,
  loginFWH,
  loginByWXCode,
  userAgree,
  getAgreements,
} from "../../api/test";

import { setUserId } from "../../utils/store";

export default {
  name: "index",
  data() {
    return {
      showCustomLoginView: false,
      open_id: "",
      wx_code: this.$route.query.code,
      user_name: "",
      password: "",
      returnUrl: "",
      isSending: false, // 是否发送验证码
      time: 59,
      timer: -1, // 倒计时计时器
      userAgreement: null,
      isShowAgreementPopup: false, // 是否展示协议弹窗
    };
  },
  created() {
    this.initAgreement()
  },

  mounted() {
    let showType = "request_open_id";
    if (
      this.$route.query.show_type &&
      this.$route.query.show_type.indexOf("show_view") !== -1
    ) {
      showType = this.$route.query.show_type;
      this.wx_code = this.$route.query.code;
      let string = this.$route.query.show_type.replace("show_view", "");
      if (string) {
        let arr = string.split("|");
        let route = "";
        for (let i = 0; i < arr.length; i++) {
          if (i == 0) {
            route = arr[i];
          } else if (i == 1) {
            route += `?${arr[i]}`;
          } else {
            route += `&${arr[i]}`;
          }
        }
        this.returnUrl = route;
      }
    }
    if (false) {
      this.open_id = "oOLA56oqOwd4e1WAzo8OpcK_Zk-k"
      this.showCustomLoginView = true
      return;
    }
    if (showType == "request_open_id") {
      this.gotoOauth();
    } else {
      //直接微信授权登录就好了
      loginByWXCode(this.wx_code).then((res) => {
        if (res.data.code === 0) {
          let result = res.data;
          this.open_id = result.data.open_id
          this.showCustomLoginView = true
          //先调试
          // setUserId(result.data._id.$id);
          // if (this.returnUrl) {
          //   this.$router.replace(this.returnUrl);
          // } else {
          //   this.$router.replace("/home");
          // }
        } else if (res.data.code == -2) {
          this.$alert("检测到您还未关注我们的公众号，请微信搜索'参号会'，然后关注~", '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        } else {
          //返回了open_id
          this.open_id = res.data.data
          this.showCustomLoginView = true
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    actionShowProtocol () {
      this.isShowAgreementPopup = true
    },
    gotoOauth() {
      const appId = "wxa6e97f80ff729b4b";

      let string = "";
      if (this.$route.query.redirect) {
        let arr = this.$route.query.redirect.split("?");
        string = arr[0];
        if (arr[1]) {
          let params = arr[1].split("&");
          for (let item of params) {
            string += `|${item}`;
          }
        }
      }

      const redirectUrl = `http://canhaohui-fwh.reachable-edu.com/login?show_type=show_view${string}`;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        redirectUrl +
        "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1";
    },
    doSend() {
      // 新增获取验证码接口
      if (this.user_name == "") {
        this.$message.warning("请输入手机号码");
        return;
      }
      if (this.isSending) return
      getPhoneCode({
        mobile: this.user_name,
      }).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.$message.success("获取验证码成功，请查看手机短信");
          this.isSending = true
          clearInterval(this.timer)
          this.time = 59
          this.timer = setInterval(() => {
            this.time--
            if (this.time <= 0) {
              this.isSending = false
              clearInterval(this.timer)
            }
          }, 1000)
        } else {
          this.$message.error(result.msg);
          this.isSending = false
        }
      }).catch(() => {
        this.isSending = false
      });
    },
    initAgreement() {
      getAgreements().then((res) => {
        let result = res.data;
        if (result.code == 0) {

          const userAgreement = result.data.filter(item => item.type === "用户协议")
          this.userAgreement = userAgreement[0]
        }
      })
    },

    doLogin() {
      if (this.user_name == "") {
        this.$message.warning("请输入邮箱或者密码")
        return
      }
      if (this.password == "") {
        this.$message.warning("请输入密码")
        return
      }
      if (!this.open_id) {
        this.$message.warning("未获取到用户openid，请刷新网页")
        return
      }
      loginFWH(this.user_name, this.password, this.open_id).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.$message.success("登录成功");
          setUserId(result.data._id.$id);
          userAgree({
            agreement_id: "66d49aeda4640b70368b4579",
            user_id: result.data._id.$id,
          }).finally(() => {
            if (this.returnUrl) {
              this.$router.replace(this.returnUrl);
            } else {
              this.$router.replace("/home");
            }
          })
        } else if (result.code == -1) {
          this.$alert("检测到您还未关注我们的公众号，请微信搜索'参号会'，然后关注~", '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
        }
        else {
          this.$message.error(result.msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.login-container {
  background-image: url("../../assets/login_bg.png");
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-hint {
  margin-top: 25px;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}

::v-deep(.el-input__inner) {
  background: #f2f8ff;
  border-radius: 15px;
  border-width: 0px;
  height: 42px;
}

.btn-login {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  background: #409eff;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
  &.disabled{
    background: #8fc0f2;
  }
}
.agreement{
  font-size: 12px;
  color: #666;
  &-link{
    color: #409eff;
  }
  &-header{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #efefef;
    font-weight: 700;
  }
  &-container{
    
    width: 80vw;
    height: 80vh;
    overflow-y: auto;
    padding: 12px;
    :deep(ul) {
      padding-left: 1.5em;
      list-style: 'disc';
    }
    :deep(ol){
      padding-left: 1.5em;
      list-style: decimal;
    }
  }
}
</style>
