<template>
  <div class="buxucixingContainer">
    <div id="scheduleTopDiv" v-if="active === 0">
      <div id="searchDiv">
        <img id="searchIconDiv" src="../assets/searchIcon.png" />

        <el-input
          id="searchInputDiv"
          v-model="searchKey"
          placeholder="输入关键词"
        />
      </div>

      <div id="changeScheduleType" @click="changeScheduleType()">
        <img
          v-if="scheduleType == 'detail'"
          id="changeTypeDiv"
          src="../assets/richengicon.png"
        />

        <img
          v-if="scheduleType == 'easy'"
          id="changeTypeDiv"
          src="../assets/richengicon1.png"
        />
      </div>
    </div>

    <!-- 筛选 Tabs -->
    <div class="buxucixingContainer-tabs" v-if="active === 0">
      <van-tabs color="#0080FF" v-model="curTab">
        <van-tab
          v-for="(item, index) in tabList"
          :title="item.name"
          :value="item.value"
          :key="index"
        ></van-tab>
      </van-tabs>
    </div>

    <div
      id="scheduleContentDiv"
      :style="{
        height: active === 0 ? `calc(100% - 176px)` : `calc(100% - 52px)`,
      }"
    >
      <div v-if="active === 0">
        <div v-if="scheduleType == 'easy'">
          <div v-for="(session, index) in getDataList" :key="index">
            
            <div id="sessionDiv"  style="position: relative;">
              
              <div id="sessionTimeDiv">
                {{
                  formatShowDate(session.date) +
                  " " +
                  session.show_start_time +
                  "-" +
                  session.show_end_time
                }}
              </div>
              <div id="sessionTypeDiv">{{ session.type }}</div>
              <div id="sessionNameDiv" class="session-name">
                <div>{{ session.name }}</div>
                <a class="watch-pdf-btn" :href="`/watch-pdf?id=${session.showFile._id.$id}&session_id=${session.showFile.session_id}`" v-if="session.showFile">
                  立即参会
                </a>
              </div>
              <div id="sessionRoomDiv">
                {{ session.meetingRoom ? session.meetingRoom.name : "" }}
              </div>
              <div v-if="session.guests && session.guests.length > 0">
                <div
                  id="guestDiv"
                  v-for="(guest, index1) in session.guests"
                  :key="index1"
                >
                  {{ guest.name + " " + guest.company }}

                  <div id="sessionGuestTitles">
                    <el-tag
                      v-for="(title, titleIndex) in guest.titles"
                      :key="titleIndex"
                      style="font-size: 11px; height: 17px; line-height: 15px"
                      type=""
                      size="mini"
                      effect="plain"
                      >{{ title }}</el-tag
                    >
                  </div>
                </div>
              </div>
              <div
                id="leftLineDiv"
                :style="'background-color:' + bgColorArr[index % 5]"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="scheduleType == 'detail'">
          <div v-for="(session, index) in getDataList" :key="index">
            <div class="sessionDetailDiv">
              <div style="display: flex; justify-content: space-between">
                <div class="sessionTimeDetailDiv">
                  {{
                    formatShowDate(session.date) +
                    " " +
                    session.show_start_time +
                    "-" +
                    session.show_end_time
                  }}
                </div>
                <div class="sessionTypeDetailDiv">{{ session.type }}</div>
              </div>

              <div class="sessionNameDetailDiv">{{ session.name }}</div>
              <div class="sessionRoomDetailDiv">
                {{ session.meetingRoom ? session.meetingRoom.name : "" }}
              </div>
              <div v-if="session.guests && session.guests.length > 0">
                <div
                  class="guestDetailDiv"
                  v-for="(guest, index1) in session.guests"
                  :key="index1"
                >
                  <div style="float: left">
                    <el-image
                      id="guestAvatarDiv"
                      :src="guest.avatar_url"
                      :fit="cover"
                    >
                    </el-image>
                  </div>

                  <div id="guestNameDiv">
                    {{ guest.name }}
                  </div>

                  <div id="guestCompanyNameDiv">
                    {{ guest.company }}
                  </div>

                  <div id="guestTitles">
                    <el-tag
                      v-for="(title, titleIndex) in guest.titles"
                      :key="titleIndex"
                      style="font-size: 11px; height: 17px; line-height: 15px"
                      type=""
                      size="mini"
                      effect="plain"
                      >{{ title }}</el-tag
                    >
                  </div>

                  <div id="guestIntroDiv" v-html="guest.intro"></div>
                </div>
              </div>

              <div
                class="leftLineDetailDiv"
                :style="'background-color:' + bgColorArr[index % 5]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="active === 1" class="snapContainer">
        <van-empty description="你还未有任何PPT快照" v-if="!snap.length" />
        <div v-for="(item, index) in snap" :key="index" class="snapItem">
          <img class="snapPic" :src="item.pic" @click="viewImg(item.pic)" />
          <div class="title">{{ item.session.name }}</div>
          <div class="des">
            {{ dayjs(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}
          </div>
          <img
            class="shanchu"
            src="../assets/shanchu.png"
            @click="remove(item)"
          />
        </div>
      </div>
      <div v-if="active === 2" class="snapContainer">
        <van-empty description="你还未有任何PPT笔记" v-if="!noteList.length" />
        <div v-for="(note, index) in noteList" :key="index" class="app-item">
          <el-card>
            <div style="display: flex">
              <img :src="note.pic" style="width: 34%" />
              <div style="margin-left: 10px">
                <p class="meeting-name">日程名称：{{ note.session.name }}</p>
                <p class="meeting-text">
                  {{ note.content }}
                </p>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div id="scheduleContentFoot">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="todo-list">日程</van-tabbar-item>
        <van-tabbar-item icon="photo-o">PPT快照</van-tabbar-item>
        <van-tabbar-item icon="records-o">笔记</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { formatDate } from "../utils/date";
import { ImagePreview } from "vant";
import {
  getSessions,
  getUserSnapshotPPTs,
  removeSnapShot,
  getUserSessionNotes,
  getShowFile,
} from "../api/test";
import dayjs from "dayjs";
import { getUserId } from "../utils/store";
export default {
  props: {
    meetingId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showFile: null, // 当前正在播放的文件
      scheduleType: "easy",
      curTab: 0, // 0:全部
      tabList: [],
      searchKey: "",
      dataList: [],
      bgColorArr: ["#E94933", "#59B1CF", "#4C7AFE", "#9D53EF", "#EF9A53"],
      active: 0,
      user_id: getUserId(),
      snap: [],
      dayjs,
      noteList: [],
    };
  },
  computed: {
    getDataList() {
      const flag = this.tabList[this.curTab]?.value;
      if (!flag) {
        return this.dataList;
      }

      switch (flag) {
        case "other":
          return this.dataList.filter((item) => !item.session_group_id);
        case "all":
          return this.dataList;
        default:
          return this.dataList.filter((item) => item.session_group_id === flag);
      }
    },
  },
  mounted() {
    this.fetchData();
    this.getSnap();
    this.getNote();
  },
  methods: {
    viewImg(url) {
      ImagePreview([url]);
    },
    getNote() {
      getUserSessionNotes(this.meetingId, this.user_id).then((res) => {
        let result = res.data.data;
        this.noteList = result;
      });
    },
    remove(item) {
      removeSnapShot({
        id: item._id.$id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.getSnap();
        }
      });
    },
    getSnap() {
      getUserSnapshotPPTs({
        user_id: this.user_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.snap = res.data.data;
        }
      });
    },
    changeScheduleType() {
      if (this.scheduleType == "easy") {
        this.scheduleType = "detail";
      } else {
        this.scheduleType = "easy";
      }
    },
    formatShowDate(time) {
      var weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      var date = new Date(time * 1000);
      var dayOfWeek = date.getDay();

      return (
        formatDate(new Date(time * 1000), "yyyy年MM月dd日") +
        "(" +
        weekdays[dayOfWeek] +
        ")"
      );
    },
    formatTime(time) {
      return (
        parseInt(time.substring(0, 1)) * 10 + parseInt(time.substring(1, 2))
      );
    },
    formatTimeToString(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },
    fetchData() {
      getSessions(this.meetingId).then((res) => {
        let result = res.data.data;
        let hasEmptyGroupID = false;
        // 生成 Tabs 数据
        let tabList = [{ name: "全部", value: "all" }];
        let tabMap = {};
        result = result.map((item) => {
          let start_time = item.start_time;
          let startTimes = start_time.split(":");
          let hours = this.formatTime(startTimes[0]) * 60 * 60;
          let mins = this.formatTime(startTimes[1]) * 60;
          // let seconds = this.formatTime(startTimes[2])
          let seconds = 0;
          let startSeconds = hours + mins + seconds;
          var totalSeconds = startSeconds + item.duration * 60;
          var hour = Math.floor(totalSeconds / (60 * 60)); // 计算小时
          totalSeconds %= 60 * 60; // 取余得到不足1小时的秒数
          var minute = Math.floor(totalSeconds / 60); // 计算分钟
          totalSeconds %= 60; // 取余得到不足1分钟的秒数
          // let show_end_time = this.formatTimeToString(hour) + ':' + this.formatTimeToString(minute) + ':' + this.formatTimeToString(totalSeconds)
          let show_end_time =
            this.formatTimeToString(hour) +
            ":" +
            this.formatTimeToString(minute);
          item.show_end_time = show_end_time;
          item.startSeconds = startSeconds;
          item.show_start_time = startTimes[0] + ":" + startTimes[1];

          if (!item.session_group_id || item.session_group_id == "") {
            hasEmptyGroupID = true;
          }

          // 生成 Tabs 数据
          if (item.session_group_id) {
            if (!tabMap[item.session_group_id]) {
              tabMap[item.session_group_id] = {
                name: item.group?.name,
                value: item.session_group_id,
              };
              tabList.push(tabMap[item.session_group_id]);
            }
          }

          item.showFile = null
          getShowFile({
            session_id: item._id.$id,
          }).then(res => {
            if (res.data.code === 0) {
              item.showFile = res.data.data
            }
          })

          return item;
        });
        result.sort((a, b) => {
          return a.date - b.date;
        });
        if (hasEmptyGroupID) {
          tabList.push({ name: "其他", value: "other" });
        }
        this.tabList = tabList;
        this.dataList = result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buxucixingContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &-tabs {
    margin-bottom: 10px;
  }

  .snapContainer {
    padding: 20px;
  }

  .snapItem {
    width: 100%;
    height: 270px;
    border-radius: 20px;
    background: #fff;
    margin-top: 10px;
    position: relative;

    .title {
      font-size: 18px;
      font-weight: bold;
      padding: 10px 20px;
      padding-bottom: 4px;
    }

    .des {
      font-size: 14px;
      color: #999;
      padding: 0 20px;
    }

    .snapPic {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
    }

    .shanchu {
      position: absolute;
      width: 30px;
      height: 22px;
      right: 20px;
      bottom: 26px;
      z-index: 999;
    }
  }

  .app-item {
    margin-bottom: 15px;

    .meeting-name {
      margin-top: -5px;
      color: #333;
      font-size: 16px;
      font-weight: bold;
    }

    .meeting-text {
      color: #666;
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.buxucixingContainer {
  .van-tabbar--fixed {
    position: relative;
  }
}
.session-name{
  display: flex;
  justify-content: space-between;
}
.watch-pdf-btn{
  flex: 0 0 auto;
  margin-left: 8px;
  border-radius: 4px;
  border: 1px solid #0080FF;
  font-size: 12px;
  padding: 0 6px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
}
</style>