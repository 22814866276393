<script>
/** 赞助商、合作伙伴信息卡片 */
export default {
  name: "Card1",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
};
</script>

<template>
  <el-card class="card">
    <div class="title-container">
      <h3>{{ data.name }}</h3>
      <img class="logo" :src="data.logo_url" alt="logo" />
    </div>
    <div class="text" v-html="data.intro"></div>
  </el-card>
</template>

<style scoped lang="scss">
.card {
  border-radius: 8px;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 50px;
}
.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 52px;
}
</style>
