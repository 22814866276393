import Cookies from 'js-cookie'

const USER_ID = 'canhaohui_fwh-user_id'
const USER_NAME = 'canhaohui_fwh-user_name'
const OPEN_ID = 'canhaohui_fwh-open_id'
const HOME_TAB_INDEX = 'canhaohui-fwh-tabIndex'

function getExpires(duration, unit) {
    const date = new Date();
    if (unit === 'day') {
        date.setDate(date.getDate() + duration);
    } else if (unit === 'hour') {
        date.setHours(date.getHours() + duration);
    } else {
        throw new Error(`Invalid unit: ${unit}`);
    }
    return date;
}

export function setHomeTabIndex(tabIndex) {
    Cookies.set(HOME_TAB_INDEX, tabIndex)
}

export function getHomeTabIndex() {
    return Cookies.get(HOME_TAB_INDEX)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id, { expires: getExpires(1, 'day') })
}
export function getUserId() {
    //"65d830eca4640bde148b4569" 
    return Cookies.get(USER_ID)
}
export function removeUserId() {
    Cookies.remove(USER_ID)
}
export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name, { expires: getExpires(1, 'day') })
}
export function getUserName() {
    return Cookies.get(USER_NAME)
}
export function removeUserName() {
    Cookies.remove(USER_NAME)
}
export function setOpenId(open_id) {
    Cookies.set(OPEN_ID, open_id, { expires: getExpires(1, 'day') })
}
export function getOpenId() {
    return Cookies.get(OPEN_ID)
}
export function removeOpenId() {
    Cookies.remove(OPEN_ID)
}

export function logout() {
    Cookies.remove(USER_ID)
}