<template>
    <div style="width: 100%;height: 100%;background-color: #F7F7F7;position: relative">
        <div class="my-top">
            <p class="my-title">我的</p>
            <div style="flex: 1;display:flex;align-items: center">
                <img v-if="userInfo.user_avatar_url" :src="userInfo.user_avatar_url" class="my-avatar">
                <img v-else src="../assets/default_avator1.png" class="my-avatar">
                <div style="width: 100%;flex: 1;padding-left:20px;display: flex;align-items: center ">
                    <div style="width: 100%;flex: 1;padding-right: 5px;box-sizing: border-box">
                        <p class="my-nickname">{{ userInfo.name }}</p>
                        <p class="my-intro">{{ userInfo.mobile }}</p>
                    </div>
                    <img src="../assets/arrow-right-white.png" style="width: 8px;height: 16px">
                </div>
            </div>
        </div>
        <div style="margin-top: 15px">
            <div class="my-item" @click="gotoMeetingSettings()">
                <p style="width: 100%;padding-left: 15px;box-sizing: border-box;flex: 1">会议提醒设置</p>
                <img src="../assets/icon_right_arrow.png" style="width: 12px;height: 18px">
            </div>
            <div class="empty-line"></div>
            <div class="my-item" @click="showLoginout = true">
                <p style="width: 100%;padding-left: 15px;box-sizing: border-box;flex: 1">退出登录</p>
                <img src="../assets/icon_right_arrow.png" style="width: 12px;height: 18px">
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="showLoginout" width="80%">
            <div style="font-size: 20px;color: black;">您确定要退出登录吗？</div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showLoginout = false">取消</el-button>
                <el-button type="primary" @click="doLogout">确定</el-button>
            </span>

        </el-dialog>
    </div>
</template>

<script>
import { getUserInfoById } from '../api/test'
import { getUserId, logout } from "../utils/store";

export default {
    name: "index",
    data() {
        return {
            showLoginout: false,
            userInfo: {}
        }
    },
    mounted() {
        this.requestUserInfo()
    },
    methods: {
        requestUserInfo() {
            getUserInfoById(getUserId()).then((res) => {
                this.userInfo = res.data
            })
        },
        doLogout() {
            logout()
            this.$router.replace('/login')
        },

        gotoMeetingSettings() {
            this.$router.push({
                path: '/meetingSetting'
            })

        }
    }
}
</script>

<style scoped lang="scss">
.my-item {
    cursor: pointer;
    height: 65px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #262C3A;
}

.empty-line {
    width: 100%;
    height: 1px;
    background-color: #EFEFEF;
    //border: 1px solid #EFEFEF;
}

.my-top {
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    background-color: transparent;
    background-image: url("../assets/home-my-top-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

.my-title {
    font-weight: bold;
    color: #FFFFFF;
    width: 100%;
    font-size: 20px;
    text-align: center;
}

.my-nickname {
    font-size: 18px;
    color: #FFFFFF;
}

.my-intro {
    font-size: 14px;
    color: #FFFFFF;
}

.my-avatar {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
}
</style>