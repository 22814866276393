<template>
    <div class="view-root">
        <h3 style="text-align: center;">会议列表</h3>
        <div v-for="(meeting, index) in dataList" :key="index" class="app-item" @click="gotoMeetingDetail(meeting)">
            <el-card>
                <p class="meeting-name">会议名称：{{ meeting.name }}</p>
                <p class="meeting-text">会议主题：{{ meeting.topic }}</p>
                <p class="meeting-text" style="line-height: 20px;">会议地址：{{ meeting.address }}</p>
                <p class="meeting-text">{{ meeting.type == '单日会议' ? '开始时间' : '开始日期' }}：{{
            formatShowTime(meeting.start_date) }} {{ meeting.type == '单日会议' ?
            meeting.start_time : '' }}</p>
                <div style="text-align: right;">
                    <p class="btn-qrcode" @click.stop="clickShowQRCode(meeting)">会议二维码</p>
                </div>
            </el-card>
        </div>
        <el-dialog :visible.sync="showQRDialog" title="会议二维码" width="85%">
            <div style="text-align: center;">
                <h3 style="text-align: center;margin: 0px;margin-bottom: 15px;font-size: 22px;">扫码加入会议</h3>
                <vue-qr :size="191" :margin="0" :auto-color="true" :dot-scale="1" :text="meetingQRUrl" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getOpenMeetings } from '../api/test.js'
import {
    formatDate
} from '../utils/date'
import '../assets/common/common.css'
import VueQr from "vue-qr";
export default ({
    name: 'index',
    components: {
        VueQr,
    },
    data() {
        return {
            dataList: [],
            meetingQRUrl: '',
            showQRDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        clickShowQRCode(meeting) {
            this.meetingQRUrl = 'http://canhaohui-fwh.reachable-edu.com/meeting?meeting_id=' + meeting._id.$id
            this.showQRDialog = true
        },

        fetchData() {
            getOpenMeetings().then((res) => {
                this.dataList = res.data.data
            })
        },
        gotoMeetingDetail(meeting) {
            this.$router.push({
                path: '/meeting',
                query: {
                    meeting_id: meeting._id.$id
                }
            })
        },

        formatShowTime(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        },
    }
})
</script>
<style scoped lang="scss">
.btn-qrcode {
    margin: 0px;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-size: 16px;
    padding: 8px 12px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    background-color: #409EFF;
}



.app-item {
    cursor: pointer;
    margin-bottom: 15px;

    .meeting-name {
        margin-top: -5px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .meeting-text {
        color: #666;
        font-size: 14px;
    }

}
</style>